import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import Header from "../Admin Dashboard/Header/Header";
import Swal from "sweetalert2";
import config from "../../config";

export default function EditGrade() {
  const { id } = useParams();
  const history = useHistory();
  const [grade, setGrade] = useState();

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${config.apiUrl}/v1/web/api/grade/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setGrade(res.data.grade);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id, token]);

  function handleChange(evt) {
    const { name, value } = evt.target;
    setGrade({
      ...grade,
      [name]: value,
    });
  }

  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${config.apiUrl}/v1/web/api/grade/update/${id}`, grade, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Updated Successfully!",
      }).then(() => {
        history.push("/add-grade");
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (!grade) {
    return <>Loading the data</>;
  }

  return (
    <div>
      <Header />
      <div className="container" id="addNewContainer">
        <form
          class="row g-3"
          oValidate
          onSubmit={(e) => onSubmitForm(e)}
          id="formSubject"
        >
          <div class="col-auto">
            <label for="inputText2" class="visually-hidden">
              Subject
            </label>
            <input
              type="text"
              class="form-control"
              id="inputText2"
              placeholder="Enter Grade"
              name="gradeName"
              defaultValue={grade.gradeName}
              onChange={handleChange}
            />
          </div>
          <div class="col-auto">
            <button
              type="submit"
              value="Submit"
              class="btn  mb-3"
              id="submitBtn"
            >
              Update Grade
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
