import React from "react";
import "./loading.css";

export default function Loading() {
  return (
    <div>
      <div className="container-md" id="loadingContaier">
        <div className="position-absolute top-50 start-50 translate-middle">
          <div className="text-center">
            <img src="./images/paper.svg" className="img-fluid" alt="..." />
          </div>

          <div className="text-center">
            <span id="loadingText">Sorry still you cannot see any data!</span>
          </div>
          <div className="text-center">
            <span id="loadingText">
              You will be able see the data after your account being approved.
            </span>
          </div>
          <div className="text-center">
            <button className="btn" id="loagindBtn">
              <img
                src="./images/user-headset.svg"
                alt="logo"
                className="img-fluid"
              />&nbsp;
              Contact Support Team
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
