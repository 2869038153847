import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Admin Dashboard/Header/Header";
import "./student.css";
import Swal from "sweetalert2";
import config from "../../config";

export default function Student() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getData(token);
        setData(tempData);
        setFilteredData(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching student data:", error);
      }
    };
    fetchData();
  }, []);

  const getData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/student/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const students = res.data?.students || [];

      return students;
    } catch (error) {
      console.log("Error fetching student data:", error);
      throw error;
    }
  };

  const removeStudent = async (_id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated. Redirecting to login page.");
        return;
      }

      const isConfirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this Student?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed.isConfirmed) {
        const finalURL = `${config.apiUrl}/v1/web/api/student/${_id}`;
        const res = await axios.delete(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(res.data);
        Swal.fire("Deleted!", "The Student has been deleted.", "success").then(
          () => {
            setData(data.filter((student) => student._id !== _id));
            setFilteredData(
              filteredData.filter((student) => student._id !== _id)
            );
          }
        );
      }
    } catch (error) {
      console.log("Error deleting student:", error);
      Swal.fire("Error!", "Failed to delete the student.", "error");
    }
  };

  const handleSearchChange = (e) => {
    const searchKey = e.target.value.toLowerCase();
    setSearchQuery(searchKey);
    setCurrentPage(1);

    if (searchKey === "") {
      setFilteredData(data);
    } else {
      const result = data.filter(
        (student) =>
          (student.name && student.name.toLowerCase().includes(searchKey)) ||
          (student.email && student.email.toLowerCase().includes(searchKey)) ||
          (student.phone && student.phone.toLowerCase().includes(searchKey)) ||
          (student.birthYear && student.birthYear.toString().includes(searchKey))
         
      );
      setFilteredData(result);
    }
  };

  const handleToggleStatus = async (student) => {
    const confirmation = await Swal.fire({
      title: `Are you sure`,
      text: `Do you want to change the status of ${student.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel!",
    });

    if (confirmation.isConfirmed) {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("User not authenticated.");
          return;
        }

        const updatedStatus =
          student.status === "active" ? "inactive" : "active";
        const finalURL = `${config.apiUrl}/v1/web/api/student/${student._id}`;
        const res = await axios.put(
          finalURL,
          { status: updatedStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const updatedStudent = res.data.student;
  
        setData((prevData) =>
          prevData.map((s) => (s._id === student._id ? updatedStudent : s))
        );
        setFilteredData((prevFilteredData) =>
          prevFilteredData.map((s) =>
            s._id === student._id ? updatedStudent : s
          )
        );

        if (updatedStudent.status === "inactive") {
          Swal.fire(
            "Status Updated",
            `The  ${student.name} has been set to inactive.`,
            "success"
          );
        }
      } catch (error) {
        console.log("Error updating teacher status:", error);
        Swal.fire("Error!", "Failed to update the Student's status.", "error");
      }
    }
  };


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <Header />

      <div className="container" id="studentContainer">
        <div className="row" id="RowCol">
          <div className="col">
            <div className="col-lg-3 mt-2 mb-2">
              <input
                className="form-control"
                type="search"
                placeholder="Search"
                name="searchQuery"
                id="search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <table className="table table-hover" id="table">
          <thead>
            <tr>
              <th scope="col">
                <i className="fas fa-list"></i>
              </th>
              <th scope="col" id="tableRow">
                Student Name
              </th>
              <th scope="col" id="tableRow">
                Email
              </th>
              <th scope="col" id="tableRow">
                Phone
              </th>
              <th scope="col" id="tableRow">
                Birth Year
              </th>
              <th scope="col" id="tableRow">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((student, index) => (
              <tr key={student._id}>
                <th scope="row">{indexOfFirstItem + index + 1}</th>
                <td id="teacherBody">
                  {" "}
                  <img
                    src="images/teacher.png"
                    alt="logo"
                    id="teacherProfile"
                  />&nbsp;&nbsp;
                  {student.name}
                </td>
                <td id="studentBody">{student.email}</td>
                <td id="studentBody">{student.phone}</td>
                <td id="studentBody">{student.birthYear}</td>
                <td>
                  <a className="btn" id="btnDownload" href="#1">
                    <i className="bi bi-download" id="downloadIcon"></i>
                  </a>
                  &nbsp;
                  <a
                    className="btn"
                    id="btnDelete"
                    href="#1"
                    onClick={() => removeStudent(student._id)}
                  >
                    <i className="bi bi-trash" id="deleteIcon"></i>
                  </a>
                  &nbsp;&nbsp;
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={student.status === "active"}
                      onChange={() => handleToggleStatus(student)}
                    />
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div id="studentPagination">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <a
                  className="page-link"
                  href="# "
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </a>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${
                    index + 1 === currentPage ? "active" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="# "
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </a>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <a
                  className="page-link"
                  href="# "
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
