import React, { useEffect } from 'react';
import {useLocation, Link } from 'react-router-dom';
import './TabComponent.css';

const TabComponent = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState('');

  
  useEffect(() => {
    if (location.pathname === '/settings') {
      setActiveTab('profile');
    } else if (location.pathname === '/billing-information') {
      setActiveTab('billing');
    }
  }, [location]);

  const handleTabClick = (tab, path) => {
    setActiveTab(tab);
  };

  return (
    <div className="tabs">
      <Link to="/settings">
      <button
        className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
        onClick={() => handleTabClick('profile')}
      >
        Profile Information
      </button>
      </Link>
      {/* <Link to ="/billing-information">
      <button
        className={`tab-button ${activeTab === 'billing' ? 'active' : ''}`}
        onClick={() => handleTabClick('billing')}
      >
        Billing Information
      </button>
      </Link> */}
    </div>
  );
};

export default TabComponent;
