import React, { useState, useEffect, useRef, useCallback } from "react";
import Header from "../../component/Dashboard/Header/Header";
import "./reportGenerate.css";
import axios from "axios";
import config from "../../config";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chart from "chart.js/auto";
import { useParams } from "react-router-dom";
import EmotionBarChart from "./EmotionBarChart";
import EmotionDownload from "./EmotionDownload";

export default function GeneratedReport() {
  const { id } = useParams();
  const [, setImotionData] = useState([]);
  const [numberOfGood, setNumberOfGood] = useState(0);
  const [numberOfBad, setNumberOfBad] = useState(0);
  const [uniqueUserIds, setUniqueUserIds] = useState([]);
  const [userEmotionCounts, setUserEmotionCounts] = useState({});
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [students, setStudents] = useState({});
  const [numberOfStudents, setNumberOfStudents] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [, setEmotionCounts] = useState({
    happy: 0,
    sad: 0,
    neutral: 0,
    surprise: 0,
  });
  const [, setExamDetails] = useState({});
  const [subjectName, setSubjectName] = useState("");
  const [gradeName, setGradeName] = useState("");
  const [examName, setExamName] = useState("");
  const [durationName, setDurationName] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [expire_date, setDate] = useState("");
  const [note, setNote] = useState("");
  const [examData, setExamData] = useState([]);
  const [selectedPinCode, setSelectedPinCode] = useState("");
  const [selectedExamId, setSelectedExamId] = useState(null);
  const [, setEmotion] = useState([]);
  const [userExamCodeMap, setUserExamCodeMap] = useState([]);
  const [passedPercentage, setPassedPercentage] = useState("0");
  const [failedPercentage, setFailedPercentage] = useState("0");
  const [overallEmotionPercentage, setOverallEmotionPercentage] = useState(0);
  const [questionDetails, setQuestionDetails] = useState([]);

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const exam_id = params.get("exam_id");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getImotionData(token);
        const uniqueData = filterUniqueUsers(tempData);

        setImotionData(tempData);
        setUniqueUserIds(uniqueData);
        setNumberOfStudents(uniqueData.length);
        await fetchStudents(token);
        countEmotions(tempData, uniqueData);
        calculateTotalEmotionCounts(tempData);
      } catch (error) {
        console.log("Error fetching subject data:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("User not authenticated.");
      return;
    }

    try {
      const examData = await getExamDetails(token);
      setExamDetails(examData);
      await fetchRelatedNames(token, examData);
      await fetchAllExams(token);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchAllExams = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/create-exam`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const Exams = res.data?.createExams || [];

      const createExams = Exams.filter((exam) => exam.examName === exam_id);

      setExamData(createExams);

      return createExams;
    } catch (error) {
      console.log("Error fetching all exams:", error);
      throw error;
    }
  };

  const getExamDetails = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/exam/${exam_id}`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data.createExam;
    } catch (error) {
      console.log("Error fetching exam data:", error);
      throw error;
    }
  };

  const fetchRelatedNames = async (token, examData) => {
    try {
      const [subjectRes, gradeRes, durationRes, examRes] = await Promise.all([
        axios.get(`${config.apiUrl}/v1/web/api/subject/${examData.subject}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.apiUrl}/v1/web/api/grade/${examData.grade}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.apiUrl}/v1/web/api/duration/${examData.duration}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.apiUrl}/v1/web/api/exam/${exam_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      setSubjectName(subjectRes.data.subject.subjectName || "");
      setGradeName(gradeRes.data.grade.gradeName || "");
      setDurationName(durationRes.data.duration.addDuration || "");
      setExamName(examRes.data.createExam.examName || "");
      setPinCode(examRes.data.createExam.pinCode || "");
      setDate(examRes.data.createExam.expire_date || "");
      setNote(examRes.data.createExam.note || "");
    } catch (error) {
      console.log("Error fetching related names:", error);
      throw error;
    }
  };

  const getImotionData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/image/exam/${exam_id}`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const imotion = res.data?.imotion || [];
      setEmotion(imotion);

      return imotion;
    } catch (error) {
      console.log("Error fetching data:", error);
      throw error;
    }
  };

  const fetchStudents = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/student`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const studentData = res.data?.students || [];
      const studentMap = {};
      studentData.forEach((student) => {
        studentMap[student._id] = student;
      });
      setStudents(studentMap);
    } catch (error) {
      console.log("Error fetching students:", error);
      throw error;
    }
  };

  const filterUniqueUsers = (data) => {
    const seen = new Set();
    return data
      .filter((imotion) => {
        const duplicate = seen.has(imotion.userId);
        seen.add(imotion.userId);
        return !duplicate;
      })
      .map((imotion) => imotion.userId);
  };

  const countEmotions = (data, uniqueUserIds) => {
    let goodCount = 0;
    let badCount = 0;
    const userCounts = {};

    uniqueUserIds.forEach((userId) => {
      userCounts[userId] = { good: 0, bad: 0 };
    });

    data.forEach((imotion) => {
      const userId = imotion.userId;
      if (
        imotion.status === "happy" ||
        imotion.status === "neutral" ||
        imotion.status === "surprise"
      ) {
        goodCount++;
        userCounts[userId].good++;
      } else if (
        imotion.status === "angry" ||
        imotion.status === "contempt" ||
        imotion.status === "disgust" ||
        imotion.status === "scared" ||
        imotion.status === "sad"
      ) {
        badCount++;
        userCounts[userId].bad++;
      }
    });

    setNumberOfGood(goodCount);
    setNumberOfBad(badCount);
    setUserEmotionCounts(userCounts);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleDownloadClick = (userId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("User not authenticated.");
      return;
    }

    const fetchData = async () => {
      try {
        const finalURL = `${config.apiUrl}/v1/web/api/image/student/${userId}`;
        const res = await axios.get(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const student = res.data?.student || [];
        const std = students[userId];
        const imotions = await getImotionData(token);
        const userImotions = imotions.filter(
          (imotion) => imotion.userId === userId
        );

        let goodCount = 0;
        let badCount = 0;
        userImotions.forEach((imotion) => {
          if (
            imotion.status === "happy" ||
            imotion.status === "neutral" ||
            imotion.status === "surprise"
          ) {
            goodCount++;
          } else if (
            imotion.status === "angry" ||
            imotion.status === "contempt" ||
            imotion.status === "disgust" ||
            imotion.status === "scared" ||
            imotion.status === "sad"
          ) {
            badCount++;
          }
        });

        const total = goodCount + badCount;
        let goodPercentage = 0;
        let badPercentage = 0;

        if (total > 0) {
          goodPercentage = ((goodCount / total) * 100).toFixed(2);
          badPercentage = ((badCount / total) * 100).toFixed(2);
        }

        setSelectedUserData({
          std,
          student,
          goodPercentage,
          badPercentage,
        });
      } catch (error) {
        console.log("Error fetching student data:", error);
      }
    };

    fetchData();
  };

  const removeEmotion = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated. Redirecting to login page.");

        return;
      }

      const isConfirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this Student?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed.isConfirmed) {
        const finalURL = `${config.apiUrl}/v1/web/api/image/${userId}`;
        await axios.delete(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUniqueUserIds(uniqueUserIds.filter((id) => id !== userId));

        if (selectedUserData?.std?._id === userId) {
          setSelectedUserData(null);
        }

        Swal.fire("Deleted!", "Student has been deleted.", "success").then();
      }
    } catch (error) {
      console.log("Error deleting Student:", error);
      Swal.fire("Error!", "Failed to delete the Student.", "error");
    }
  };

  const removeAllEmotion = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated. Redirecting to login page.");

        return;
      }

      const isConfirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this All Data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed.isConfirmed) {
        const finalURL = `${config.apiUrl}/v1/web/api/image/all/${exam_id}`;
        await axios.delete(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        Swal.fire("Deleted!", " All student has been deleted.", "success").then(
          () => {
            window.location.reload(false);
          }
        );
      }
    } catch (error) {
      console.log("Error deleting Student:", error);
      Swal.fire("Error!", "Failed to delete the Student.", "error");
    }
  };

  const calculateTotalEmotionCounts = (data) => {
    const counts = {
      happy: 0,
      sad: 0,
      neutral: 0,
      surprise: 0,
    };

    data.forEach((imotion) => {
      if (imotion.status === "happy") counts.happy++;
      if (imotion.status === "sad") counts.sad++;
      if (imotion.status === "neutral") counts.neutral++;
      if (imotion.status === "surprise") counts.surprise++;
    });

    setEmotionCounts(counts);
    initializeChart(counts);
    calculateOverallEmotionPercentage(counts);
  };

  const calculateOverallEmotionPercentage = (counts) => {
    const totalStudents =
      counts.happy + counts.sad + counts.neutral + counts.surprise;

    if (totalStudents === 0) return;

    const emotionWeights = {
      happy: 80,
      neutral: 60,
      sad: 30,
      surprise: 40,
    };

    if (
      (counts.happy === totalStudents && counts.happy !== 0) ||
      (counts.neutral === totalStudents && counts.neutral !== 0) ||
      (counts.sad === totalStudents && counts.sad !== 0) ||
      (counts.surprise === totalStudents && counts.surprise !== 0)
    ) {
      setOverallEmotionPercentage(100);
      return;
    }

    const overallPercentage =
      (emotionWeights.happy * counts.happy +
        emotionWeights.neutral * counts.neutral +
        emotionWeights.sad * counts.sad +
        emotionWeights.surprise * counts.surprise) /
      totalStudents;

    setOverallEmotionPercentage(overallPercentage);
  };

  const initializeChart = (counts) => {
    const ctx = chartRef.current;
    if (!ctx) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    chartInstance.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["Happy", "Sad", "Neutral", "Surprise"],
        datasets: [
          {
            label: "Count",
            data: [counts.happy, counts.sad, counts.neutral, counts.surprise],
            backgroundColor: ["#4DB6AC", "#F65656", "#FFA216", "#9747FF"],
            borderColor: ["#4DB6AC", "#F65656", "#FFA216", "#9747FF"],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              color: "#333",
              font: {
                weight: 1000,
              },
            },
            grid: {
              color: "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              color: "#333",
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              color: "#333",
              font: {
                weight: 1000,
              },
            },
            grid: {
              color: "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              color: "#333",
            },
          },
        },
        animation: {
          duration: 1000,
          easing: "easeInOutQuart",
        },
      },
    });
  };

  const downloadChartReport = async () => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(40, 55, 71);
    doc.text("FEDES Report: Emotional Detection Results", 20, 20);

    doc.setFontSize(14);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(80, 100, 130);
    doc.text("Overall Results and Emotional Statistics", 20, 30);

    const canvas = chartRef.current;
    if (canvas) {
      const image = canvas.toDataURL("image/png");
      doc.addImage(image, "PNG", 20, 40, 170, 100);
    }

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 0, 0);
    doc.text("Pass,Fail $ Emotion Statistics", 20, 160);

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(
      `Overall Emotion Percentage: ${overallEmotionPercentage.toFixed(2)}%`,
      20,
      170
    );
    doc.text(`Students Passed: ${passedPercentage}%`, 20, 180);
    doc.text(`Students Failed: ${failedPercentage}%`, 20, 190);

    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    doc.setTextColor(150, 150, 150);
    doc.text("Generated by FEDES System", 20, 280);

    doc.rect(15, 10, 180, 270);

    doc.save("chart_report.pdf");
  };

  const downloadReport = () => {
    if (!selectedUserData) return;

    const { std, goodPercentage, badPercentage } = selectedUserData;
    const userId = std._id;
    const userCounts = userEmotionCounts[userId] || { good: 0, bad: 0 };

    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(40, 55, 71);
    doc.text("FEDES Report: Emotional Detection Results", 20, 20);

    doc.setFontSize(14);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(80, 100, 130);
    doc.text("Student Details", 20, 30);

    const tableData = [
      ["Student Name", std.name],
      ["Email", std.email],
      ["Phone", std.phone],
      ["Birth Year", std.birthYear],
      ["No. of Good", userCounts.good],
      ["No. of Bad", userCounts.bad],
      ["Good Emotions Percentage", `${goodPercentage}%`],
      ["Bad Emotions Percentage", `${badPercentage}%`],
    ];

    const columnStyles = {
      0: { cellWidth: 90 },
      1: { cellWidth: 90 },
    };

    doc.autoTable({
      head: [["Attribute", "Value"]],
      body: tableData,

      startY: 40,
      theme: "striped",
      columnStyles,
    });

    doc.setFontSize(14);
    doc.text("Recommendation:", 10, doc.autoTable.previous.finalY + 10);

    doc.setFontSize(12);
    doc.text(
      "The face emotion detection analysis indicates that the student remained actively engaged " +
        "and exhibited a range of emotional states throughout the exam.",
      10,
      doc.autoTable.previous.finalY + 20,
      { maxWidth: 150 }
    );

    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    doc.setTextColor(150, 150, 150);
    doc.text("Generated by FEDES System", 20, 280);

    doc.rect(6, 10, 200, 270);

    doc.save(`${std.name}_report.pdf`);
  };

  const handlePinCodeChange = async (e) => {
    const [examId, pinCode] = e.target.value.split("|");
    setSelectedPinCode(pinCode);
    setSelectedExamId(examId);

    const token = localStorage.getItem("token");
    if (!token) {
      console.log("User not authenticated.");
      return;
    }

    try {
      const finalURL = `${config.apiUrl}/v1/web/api/image/pin/${pinCode}`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = res.data.imotion || [];

      setImotionData(data);

      if (data.length > 0 && data[0].length > 0) {
        const firstEntry = data[0][0];
        const PinNote = firstEntry.note;
        const pin = firstEntry.pinCode;
        const pinDate = firstEntry.expire_date;
        setNote(PinNote);
        setPinCode(pin);
        setDate(pinDate);
      } else {
        console.log("No imotion data found");
        setNote(note);
        setPinCode(pinCode);
      }
    } catch (error) {
      console.log("Error fetching data by pin code:", error);
    }
  };

  const mapUserIdToExamCodeId = (imotionData) => {
    const userExamCodeMap = {};
    imotionData.forEach((item) => {
      userExamCodeMap[item.userId] = item.examcode_id;
    });
    return userExamCodeMap;
  };

  useEffect(() => {
    const fetchAndMapData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        const imotionData = await getImotionData(token);
        const userExamCodeMap = mapUserIdToExamCodeId(imotionData);
        setUserExamCodeMap(userExamCodeMap);
      }
    };

    fetchAndMapData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredUserIds = uniqueUserIds.filter((userId) => {
    const studentName = students[userId]?.name.toLowerCase() || "";
    const userExamCodeId = userExamCodeMap[userId];

    return (
      studentName.includes(searchQuery) &&
      (!selectedExamId || userExamCodeId === selectedExamId)
    );
  });

  const handleReset = () => {
    setSelectedExamId("");
    setSelectedPinCode("");

    setImotionData([]);
    setNote("");
    setPinCode("");
    setDate("");
  };

  const fetchResults = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("User not authenticated.");
      return;
    }

    try {
      const finalURL = `${config.apiUrl}/v1/web/api/question/point/${exam_id}`;
      const response = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const resultData = response.data.point || [];

      calculatePassFailPercentages(resultData);
    } catch (error) {
      console.log("Error fetching results:", error);
    }
  };

  const calculatePassFailPercentages = (results) => {
    if (results.length === 0) {
      console.log("No results available.");
      setPassedPercentage("0");
      setFailedPercentage("0");
      return;
    }

    let totalAnswers = 0;
    let correctAnswers = 0;
    results.forEach((result) => {
      totalAnswers += result.result.length;
      correctAnswers += result.result.filter(
        (r) => r.noOfpoint === true
      ).length;
    });

    const passedPercentage =
      totalAnswers > 0
        ? ((correctAnswers / totalAnswers) * 100).toFixed(2)
        : "0";
    const failedPercentage =
      totalAnswers > 0
        ? (((totalAnswers - correctAnswers) / totalAnswers) * 100).toFixed(2)
        : "0";

    setPassedPercentage(passedPercentage);
    setFailedPercentage(failedPercentage);
  };

  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchQuestionData = async () => {
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const res = await fetch(
          `${config.apiUrl}/v1/web/api/report/question/${exam_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        const report = data.report[0];
        processQuestionData(report);
      } catch (error) {
        console.log("Error fetching question data:", error);
      }
    };

    fetchQuestionData();
  }, [exam_id, token]);

  const processQuestionData = (data) => {
    const { questions, emotionData, pointData } = data;

    const questionDetails = questions.map((question) => {
      const questionId = question._id;

      const results = pointData
        .map((entry) => entry.result)
        .flat()
        .filter((result) => result.questionId === questionId);

      const passCount = results.filter((result) => result.noOfpoint).length;
      const failCount = results.length - passCount;

      let passPercentage = 0;
      let failPercentage = 0;

      if (results.length > 0) {
        passPercentage = ((passCount / results.length) * 100).toFixed(2);
        failPercentage = ((failCount / results.length) * 100).toFixed(2);
      }

      const emotions = emotionData.filter(
        (emotion) => emotion.questionId === questionId
      );

      const emotionCounts = emotions.reduce(
        (acc, curr) => {
          acc[curr.status] = (acc[curr.status] || 0) + 1;
          return acc;
        },
        { happy: 0, sad: 0, neutral: 0, surprise: 0 }
      );

      return {
        questionTitle: question.questionTitle,
        answer: question.correctAnswer,
        passPercentage,
        failPercentage,
        emotionCounts,
      };
    });

    setQuestionDetails(questionDetails);
  };

  const renderQuestionTitle = (title) => {
    return { __html: title };
  };

  return (
    <div>
      <Header />
      <div id="examContainer">
        <div
          id="examDetails"
          className="exam-details"
          style={{ marginLeft: "3%" }}
        >
          <div className="detail-item">
            <strong id="examhHeaderName">Exam Name:</strong>
            <span id="examDataName"> {examName} </span>
          </div>
          <div className="detail-item">
            <strong id="examhHeaderName">Subject:</strong>
            <span id="examDataName"> {subjectName} </span>
          </div>
          <div className="detail-item">
            <strong id="examhHeaderName">Duration:</strong>
            <span id="examDataName"> {durationName} </span>
          </div>
          <div className="detail-item">
            <strong id="examhHeaderName">Grade:</strong>
            <span id="examDataName"> {gradeName} </span>
          </div>
          <div className="detail-item">
            <strong id="examhHeaderName">Pin Code:</strong>
            <span id="examDataName"> {pinCode} </span>
          </div>
          <div className="detail-item">
            <strong id="examhHeaderName">Expire Date:</strong>
            <span id="examDataName">
              {" "}
              {new Date(expire_date).toLocaleDateString()}{" "}
            </span>
          </div>
          <div className="detail-item">
            <strong id="examhHeaderName">Note:</strong>
            <span id="examDataName"> {note} </span>
          </div>
        </div>
        <div className="row" id="examRow1">
          <div className="col-md-2" id="examCol">
            <div className="card" id="examCard1">
              <div className="card-body" id="examBody">
                <h5 className="card-title" id="examTitle">
                  NO. OF TOTAL GOOD
                </h5>
                <span id="examNumber">{numberOfGood}</span>
              </div>
            </div>
          </div>

          <div className="col-md-2" id="examCol">
            <div className="card" id="examCard1">
              <div className="card-body" id="examBody">
                <h5 className="card-title" id="examTitle">
                  NO. OF TOTAL BAD
                </h5>
                <span id="examNumber">{numberOfBad}</span>
              </div>
            </div>
          </div>

          <div className="col-md-2" id="examCol">
            <div className="card" id="examCard1">
              <div className="card-body" id="examBody">
                <h5 className="card-title" id="examTitle">
                  NO. OF FACED STUDENTS
                </h5>
                <span id="examNumber">{numberOfStudents}</span>
              </div>
            </div>
          </div>

          <div className="col-md-2" id="examColBtn">
            <div className="row" id="dowloadRow">
              <button
                className="btn"
                id="downloadAll"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal-all"
              >
                <i className="bi bi-download" id="dowloadIcon"></i>
                &nbsp;Overall
              </button>
            </div>

            <div className="row" id="deleteRow">
              <button
                className="btn"
                id="questionwise"
                data-bs-toggle="modal"
                data-bs-target="#ushan"
              >
                <i className="bi bi-download"></i>
                &nbsp;Question wise
              </button>
            </div>

            <div className="row" id="deleteRow">
              <button
                className="btn"
                id="deleteAll"
                onClick={() => removeAllEmotion(exam_id)}
              >
                <i className="bi bi-trash" id="deleteIcon"></i>&nbsp;Delete All
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container" id="ReportContainer">
        <div className="row" id="RowCol">
          <div className="col">
            <div className="col-lg-3 mt-2 mb-2" id="search">
              <input
                className="form-control"
                type="search"
                placeholder="Search"
                name="serchQuery"
                id="search"
                onChange={handleSearch}
              />
            </div>
          </div>

          <div className="col" id="btnCol1">
            <select
              id="filter1"
              className="form-select"
              value={`${selectedExamId}|${selectedPinCode}`}
              onChange={handlePinCodeChange}
            >
              <option value="">Pin Code</option>
              {Object.values(examData).map((exam, index) => (
                <option key={index} value={`${exam._id}|${exam.pinCode}`}>
                  {exam.pinCode}
                </option>
              ))}
            </select>
            <button
              type="button"
              className="btn"
              id="resetBtn"
              onClick={handleReset}
            >
              <i class="bi bi-x-circle" id="resetIcon"></i>
            </button>
          </div>
        </div>

        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">
                <i className="fas fa-list"></i>
              </th>
              <th scope="col" id="headName">
                Student Name
              </th>
              <th scope="col" id="headName">
                Good Count
              </th>
              <th scope="col" id="headName">
                Bad Count
              </th>
              <th scope="col" id="headName">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {filteredUserIds.map((userId, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td id="supplier">
                  <img
                    src="images/teacher.png"
                    alt="logo"
                    id="teacherProfile"
                  />
                  &nbsp;&nbsp;{students[userId]?.name}
                </td>
                <td id="supplier">{userEmotionCounts[userId]?.good || 0}</td>
                <td id="supplier">{userEmotionCounts[userId]?.bad || 0}</td>
                <td>
                  <a
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    id="btnDownload"
                    onClick={() => handleDownloadClick(userId)}
                    style={{ border: "none" }}
                    href="# "
                  >
                    <i className="bi bi-download" id="dowloadIcon"></i>
                  </a>
                  &nbsp;
                  <a
                    className="btn"
                    id="btnDelete"
                    href="#1"
                    onClick={() => removeEmotion(userId)}
                  >
                    <i className="bi bi-trash" id="deleteIcon"></i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/*<!-- Modal -->*/}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <button id="downloadButton" onClick={downloadReport}>
                  <i className="bi bi-download" id="dowloadIconReport"></i>
                  &nbsp;Download
                </button>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div id="reportLogoDiv">
                <img src="/images/report.png" alt="logo" id="reportLogo2" />
              </div>

              {selectedUserData ? (
                <>
                  <div id="reportTextDiv">
                    <span id="reportText">
                      The face emotional detection results report from FEDES
                    </span>
                  </div>
                  <br></br>

                  <div className="text-left">
                    <strong>Student Details:</strong>
                  </div>
                  <table className="table table-bordered mt-3">
                    <tbody>
                      <tr>
                        <th>Student Name:</th>
                        <td id="modalTableData">{selectedUserData.std.name}</td>
                      </tr>
                      <tr>
                        <th>Email:</th>
                        <td id="modalTableData">
                          {selectedUserData.std.email}
                        </td>
                      </tr>
                      <tr>
                        <th>Phone:</th>
                        <td id="modalTableData">
                          {selectedUserData.std.phone}
                        </td>
                      </tr>
                      <tr>
                        <th>Birth Year:</th>
                        <td id="modalTableData">
                          {selectedUserData.std.birthYear}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div id="reportTitleSpanDiv">
                    <span id="reportTitleSpan">
                      Student’s emotion during the exam
                    </span>
                  </div>

                  <div id="AllCard">
                    <div className="row">
                      <div className="col" id="card">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title" id="cardTitleReport">
                              Student Engagement
                            </h5>
                            <p className="card-text" id="cardTextReport">
                              Good Emotions Percentage:{" "}
                              {selectedUserData.goodPercentage}%
                            </p>
                            <span id="StatusGood">Good!</span>
                          </div>
                        </div>
                      </div>

                      <div className="col">
                        <div className="card" id="card">
                          <div className="card-body">
                            <h5 className="card-title" id="cardTitleReport">
                              Emotional State
                            </h5>
                            <p className="card-text" id="cardTextReport">
                              Bad Emotions Percentage:{" "}
                              {selectedUserData.badPercentage}%
                            </p>
                            <span id="StatusBad">Bad!</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="modal-footer">
              <span id="recommendation">Recommendation:</span>
              <span id="footerDetails">
                The face emotion detection analysis indicates that the student
                remained actively engaged and exhibited a range of emotional
                states throughout the exam.
              </span>
            </div>
          </div>
        </div>
      </div>

      {/*<!-- Modal-2 -->*/}
      <div
        className="modal fade"
        id="exampleModal-all"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <button id="downloadButton" onClick={downloadChartReport}>
                  <i className="bi bi-download" id="dowloadIconReport"></i>
                  &nbsp;Download
                </button>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div id="reportLogoDiv">
                <img src="/images/report.png" alt="logo" id="reportLogo2" />
              </div>

              <>
                <div id="reportTextDiv">
                  <span id="reportText">
                    The face emotional detection results report from FEDES
                  </span>
                </div>
                <br></br>

                <div className="text-left">
                  <span id="reportTitleSpan">Overall Results</span>
                </div>

                <div id="AllCard">
                  <div className="row">
                    <div className="col" id="card">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title" id="cardTitleReport">
                            STUDENTS PASSED
                          </h5>

                          <span id="passPresentage">{passedPercentage}%</span>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="card" id="card">
                        <div className="card-body">
                          <h5 className="card-title" id="cardTitleReport">
                            STUDENTS FAILED
                          </h5>
                          <span id="failPresentage">{failedPercentage}%</span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="text-left"
                      style={{ marginTop: "3%", marginBottom: "3%" }}
                    >
                      <span id="reportTitleSpan">Overall Emotions</span>
                    </div>
                    <div className="text-center">
                      <div className="col">
                        <div className="card" id="card">
                          <div className="card-body">
                            <h5 className="text-center" id="cardTitleReport">
                              STUDENTS EMOTION PERCENTAGE
                            </h5>
                            <div className="text-center">
                              <span id="emotionPresentage">
                                {overallEmotionPercentage.toFixed(2)}%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="text-left"
                      style={{ marginTop: "5%", marginBottom: "5%" }}
                    >
                      <span id="reportTitleSpan">Emotional Statistics</span>
                    </div>

                    <div id="barChartDiv">
                      <canvas id="barChart" ref={chartRef}></canvas>
                    </div>
                  </div>
                </div>
              </>
            </div>
            <div className="modal-footer">
              <span id="recommendation">Recommendation:</span>
              <span id="footerDetails">
                The face emotion detection analysis indicates that the student
                remained actively engaged and exhibited a range of emotional
                states throughout the exam.
              </span>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- Modal-3 -->*/}
      <div
        className="modal fade"
        id="ushan"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <EmotionDownload
                  questionDetails={questionDetails}
                  numberOfStudents={numberOfStudents}
                  passedPercentage={passedPercentage}
                  failedPercentage={failedPercentage}
                />
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div id="reportLogoDiv">
                <img src="/images/report.png" alt="logo" id="reportLogo2" />
              </div>

              <>
                <div id="reportTextDiv">
                  <span id="reportText">
                    The face emotional detection results report from FEDES
                  </span>
                </div>
                <br></br>

                <div className="text-left">
                  <span id="reportTitleSpan">Overall Results</span>
                </div>
                <div id="modal3StudentTitle">
                  <div>
                    <span className="card-title" id="studentTitles">
                      TOTAL STUDENTS :
                    </span>
                    &nbsp;
                    <span id="model3NoOfStudent">{numberOfStudents}</span>
                  </div>
                  <div>
                    <span className="card-title" id="studentTitles">
                      STUDENTS PASSED :
                    </span>
                    &nbsp;
                    <span id="model3NoOfPassStudent">{passedPercentage}%</span>
                  </div>
                  <div>
                    <span className="card-title" id="studentTitles">
                      STUDENTS FAILED :
                    </span>
                    &nbsp;
                    <span id="model3NoOfFailStudent">{failedPercentage}%</span>
                  </div>
                </div>
                <div id="questionDetails">
                  {questionDetails.map((detail, index) => (
                    <div key={index} className="dataSet">
                      <p>
                        <div style={{ marginBottom: "2%" }}>
                          <span id="quetionTitleHead">
                            Question {index + 1} :
                          </span>
                        </div>
                        <strong>
                          <span
                            dangerouslySetInnerHTML={renderQuestionTitle(
                              detail.questionTitle
                            )}
                          />
                        </strong>
                      </p>

                      <p>
                        <span id="quetionTitle">Answer :</span>{" "}
                        <span
                          dangerouslySetInnerHTML={renderQuestionTitle(
                            detail.answer
                          )}
                        ></span>
                      </p>
                      <p>
                        <span id="quetionTitle">Student Pass :</span>{" "}
                        {detail.passPercentage}%
                      </p>
                      <p>
                        <span id="quetionTitle">Student Fail :</span>{" "}
                        {detail.failPercentage}%
                      </p>
                      <p>
                        <span id="quetionTitle">Emotion Count Chart :</span>
                      </p>

                      <EmotionBarChart emotionCounts={detail.emotionCounts} />
                    </div>
                  ))}
                </div>
              </>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
