import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Admin Dashboard/Header/Header";
import config from "../../config";
import { useLocation } from "react-router-dom";

export default function CreatedExamList() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);
  const [durations, setDurations] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const teacherID = params.get("teacherId");

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const [
          examsData,
          subjectsData,
          gradesData,
          durationsData,
          teacherData,
        ] = await Promise.all([
          getExamsData(token),
          getSubjectsData(token),
          getGradesData(token),
          getDurationsData(token),
          getTeachersData(token),
        ]);
        setData(examsData);
        setSubjects(subjectsData);
        setGrades(gradesData);
        setDurations(durationsData);
        setTeachers(teacherData);

        // Set initial teacher selection based on teacherID from URL
        if (teacherID) {
          setSelectedTeacher(teacherID);
          filterData(searchQuery, teacherID, examsData);
        } else {
          setFilteredData(examsData);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacherID]);

  const getExamsData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/exam/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data?.createExams || [];
    } catch (error) {
      console.log("Error fetching exam data:", error);
      throw error;
    }
  };

  const getSubjectsData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/subject/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data?.subjects || [];
    } catch (error) {
      console.log("Error fetching subject data:", error);
      throw error;
    }
  };

  const getGradesData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/grade/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data?.grades || [];
    } catch (error) {
      console.log("Error fetching grade data:", error);
      throw error;
    }
  };

  const getDurationsData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/duration/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data?.durations || [];
    } catch (error) {
      console.log("Error fetching duration data:", error);
      throw error;
    }
  };

  const getTeachersData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/teacher/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data?.teachers || [];
    } catch (error) {
      console.log("Error fetching teachers data:", error);
      throw error;
    }
  };

  const getSubjectName = (subjectId) => {
    const subject = subjects.find((subject) => subject._id === subjectId);
    return subject ? subject.subjectName : "Unknown";
  };

  const getGradeName = (gradeId) => {
    const grade = grades.find((grade) => grade._id === gradeId);
    return grade ? grade.gradeName : "Unknown";
  };

  const getDurationName = (durationId) => {
    const duration = durations.find((duration) => duration._id === durationId);
    return duration ? duration.addDuration : "Unknown";
  };

  const getTeacherName = (teacherId) => {
    const teacher = teachers.find((teacher) => teacher._id === teacherId);
    return teacher ? teacher.Name : "Unknown";
  };

  const handleSearchChange = (e) => {
    const searchKey = e.target.value.toLowerCase();
    setSearchQuery(searchKey);
    setCurrentPage(1);
    filterData(searchKey, selectedTeacher);
  };

  const handleTeacherChange = (e) => {
    const teacherId = e.target.value;
    setSelectedTeacher(teacherId);
    setCurrentPage(1);
    filterData(searchQuery, teacherId);
  };

  const filterData = (searchKey, teacherId, exams = data) => {
    let filtered = exams;

    if (searchKey) {
      filtered = filtered.filter(
        (createExam) =>
          (createExam.examName &&
            createExam.examName.toLowerCase().includes(searchKey)) ||
          (createExam.subject &&
            getSubjectName(createExam.subject)
              .toLowerCase()
              .includes(searchKey)) ||
          (createExam.grade &&
            getGradeName(createExam.grade).toLowerCase().includes(searchKey)) ||
          (createExam.duration &&
            getDurationName(createExam.duration)
              .toLowerCase()
              .includes(searchKey)) ||
          (createExam.addedBy &&
            getTeacherName(createExam.addedBy)
              .toLowerCase()
              .includes(searchKey))
      );
    }

    if (teacherId) {
      filtered = filtered.filter(
        (createExam) => createExam.addedBy === teacherId
      );
    }

    setFilteredData(filtered);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <Header />

      <div className="container" id="studentContainer">
        <div className="row" id="RowCol">
          <div className="col-lg-4 mt-3 mb-2">
            <input
              className="form-control"
              type="search"
              placeholder="Search"
              name="searchQuery"
              id="search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="col-lg-3 mt-3 mb-2">
            <div className="input-group">
              <select
                className="form-control"
                id="teacherSelect"
                style={{ borderRadius: "16px" }}
                value={selectedTeacher}
                onChange={handleTeacherChange}
              >
                <option value="">
                  <span id="tableRow">Select Teacher</span>
                </option>
                {teachers.map((teacher) => (
                  <option key={teacher._id} value={teacher._id}>
                    {teacher.Name}
                  </option>
                ))}
              </select>
              &nbsp;&nbsp;
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="basic-addon1"
                  style={{ backgroundColor: "#4db6ac1f" }}
                >
                  <i className="bi bi-funnel" style={{ color: "#4db6ac" }}></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <table className="table table-hover" id="table">
          <thead>
            <tr>
              <th scope="col">
                <i className="fas fa-list"></i>
              </th>
              <th scope="col" id="tableRow">
                Teacher Name
              </th>
              <th scope="col" id="tableRow">
                Exam Name
              </th>
              <th scope="col" id="tableRow">
                Subject
              </th>
              <th scope="col" id="tableRow">
                Grade
              </th>
              <th scope="col" id="tableRow">
                Duration
              </th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((createExam, index) => (
              <tr key={createExam._id}>
                <th scope="row">{indexOfFirstItem + index + 1}</th>
                <td id="studentBody">
                  {" "}
                  <img
                    src="images/teacher.png"
                    alt="logo"
                    id="teacherProfile"
                  />
                  &nbsp;&nbsp;{getTeacherName(createExam.addedBy)}
                </td>
                <td id="teacherBody">{createExam.examName}</td>
                <td id="studentBody">{getSubjectName(createExam.subject)}</td>
                <td id="studentBody">{getGradeName(createExam.grade)}</td>
                <td id="studentBody">{getDurationName(createExam.duration)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div id="studentPagination">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <a
                  className="page-link"
                  href="# "
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </a>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${
                    index + 1 === currentPage ? "active" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="# "
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </a>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <a
                  className="page-link"
                  href="# "
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
