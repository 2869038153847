import React, { Component } from 'react'
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import {BrowserRouter, Route} from "react-router-dom";
import Login from "./component/Login/Login"
import Report from './component/Reports/Report';
import Index  from "./component/Satatistics"
import Physics  from "./pages/PhysicsExam/Physics"
import index  from "./component/AdminSatatistics/Index"
import Teachers from './component/Teachers/Teachers';
import Account from './component/AccountSetting/Account';
import CreateExam from './component/CreateExam/CreateExam';
import AddQuestions from './component/AddQuestions/AddQuestions';
import Student from './component/AdminPageStudent/Student';
import BillingInformation from './component/BillingInformation/BillingInformation';
import addNew from './component/AddNew/addNew';
import AddSubject from './component/AddSubject/AddSubject';
import EditSubject from './component/AddSubject/EditSubject';
import AddGrade from './component/AddGrade/AddGrade';
import EditGrade from './component/AddGrade/EditGrade';
import AddDuration from './component/AddDuration/AddDuration';
import EditDuration from './component/AddDuration/EditDuration';
import CreatedExamList from "./component/CreatedExam/CreatedExamList"
import GetQuestionByExamName from './component/CreatedExam/GetQuestionByExamName'
import QuestionDetails from './component/CreatedExam/QuestionDetails';
import EditQuestions from './component/CreatedExam/EditQuestions';
import Payments from './component/AdminPayment/Payments'
import TeacherRegister from './component/TeacherRegister/TeacherRegister';
import AddBanner from './component/AddBanner/AddBanner';
import Loading from './component/LoadingPage/Loading';
import AddCreatedExam from './component/ModifyPinCode/AddCreatedExam';
import EditCreatedExam from './component/ModifyPinCode/EditCreatedExam';
import CreatedExam from './component/AdminCreatedExam/CreatedExamList';
import GeneratedReport from './component/GenerateReport/GeneratedReport'
import BuyCredits from './component/BuyCredit/BuyCredits';
import DefaultList from './component/UpdateCredit/DefaultList';
import History from './component/PurchaseHistory/History';
import GetEmotionImages from './component/EmotionImages/GetEmotionImages';



export default class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
        
       <Route path="/" exact component={Login}></Route>
       <Route path="/dashboard" exact component={Index}></Route>
       <Route path="/report" exact component={Report}></Route>
       <Route path="/physics-exam" exact component={Physics}></Route>
       <Route path="/admin-dashboard" exact component={index}></Route>
       <Route path="/teachers" exact component={Teachers}></Route> 
       <Route path="/settings" exact component={Account}></Route>
       <Route path="/create-exam" exact component={CreateExam}></Route>
       <Route path="/add-questions"  exact component={AddQuestions}></Route>
       <Route path="/students" exact component={Student}></Route>
       <Route path="/billing-information" exact component={BillingInformation}></Route>
       <Route path="/add-new" exact component={addNew}></Route>
       <Route path="/add-subject" exact component={AddSubject}></Route>
       <Route path="/edit-subject/:id" component={EditSubject}></Route>
       <Route path="/add-grade" exact component={AddGrade}></Route>
       <Route path="/edit-grade/:id" component={EditGrade}></Route>
       <Route path="/add-duration" exact component={AddDuration}></Route>
       <Route path="/edit-duration/:id" component={EditDuration}></Route>
       <Route path="/created-exam" exact component={CreatedExamList}></Route>
       <Route path="/get-question-by-examid/:exam_id" component={GetQuestionByExamName}></Route>
       <Route path="/question/:id"  component={QuestionDetails}></Route>
       <Route path="/edit/question/:id"  component={EditQuestions}></Route>
       <Route path="/admin/payments"  component={Payments}></Route>
       <Route path="/teacher/register"  component={TeacherRegister}></Route>
       <Route path="/add-banner"  component={AddBanner}></Route>
       <Route path="/loading"  component={Loading}></Route>
       <Route path="/add-pincode"  component={AddCreatedExam}></Route>
       <Route path="/edit-createExam/:id"  component={EditCreatedExam}></Route>
       <Route path="/admin-create-exam-list"  component={CreatedExam}></Route>
       <Route path="/report-generate"  component={GeneratedReport}></Route>
       <Route path="/payment"  component={BuyCredits}></Route>
       <Route path="/update-defaultPoint"  component={DefaultList}></Route>
       <Route path="/purchase-history"  component={History}></Route>
       <Route path="/emotion-images"  component={GetEmotionImages}></Route>

   </BrowserRouter>
      </div>
    )
  }
}

