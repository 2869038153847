import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Admin Dashboard/Header/Header";
import "./Add.css";
import Swal from "sweetalert2";
import config from "../../config";
import { Link } from "react-router-dom";

export default function SubjectList() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getData(token);
        setData(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching subject data:", error);
      }
    };
    fetchData();
  }, []);

  const getData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/subject/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const subjects = res.data?.subjects || [];

      return subjects;
    } catch (error) {
      console.log("Error fetching Subject data:", error);
      throw error;
    }
  };

  const removeSubject = async (_id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated. Redirecting to login page.");
        return;
      }

      const isConfirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this subject?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed.isConfirmed) {
        const finalURL = `${config.apiUrl}/v1/web/api/subject/${_id}`;
        const res = await axios.delete(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(res.data);
        Swal.fire("Deleted!", "The subject has been deleted.", "success").then(
          () => {
            window.location.reload(false);
          }
        );
      }
    } catch (error) {
      console.log("Error deleting subjects:", error);
      Swal.fire("Error!", "Failed to delete the subject.", "error");
    }
  };

  return (
    <div>
      <Header />
      <div className="container" id="addNewContainer1">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">
                <i className="fas fa-list"></i>
              </th>
              <th scope="col">Subject Name</th>
              <th scope="col">Action</th>
            </tr>
          </thead>

          <tbody>
            {data.map((subject, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td id="supplier">{subject.subjectName}</td>
                <td>
                  <Link
                    className="btn"
                    id="editBtn"
                    to={`/edit-subject/${subject._id}`}
                  >
                    <i className="fas fa-edit" id="editIcon"></i>
                  </Link>
                  &nbsp;
                  <button
                    className="btn"
                    id="editDelete"
                    onClick={() => removeSubject(subject._id)}
                  >
                    <i className="fas fa-trash-alt" id="DeleteIcon"></i>&nbsp;
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
