import React, { useState, useEffect } from "react";
import config from "../../../../config/index";
import axios from "axios";

function NavAvatar() {
  const userId = localStorage.getItem("userId");
  const [teacher, setTeacher] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${config.apiUrl}/v1/web/api/teacher/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTeacher(res.data.teacher);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [userId, token]);

  if (!teacher) {
    return null; 
  }

  return (
    <li className="nav-item dropdown pe-3">
      <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#1">
        <img src={teacher.profilePhoto || "/images/teacher.png"} alt="Profile" className="rounded-circle" />&nbsp;
        <span className="d-none d-md-block">{teacher.Name}</span>
      </a>
    </li>
  );
}

export default NavAvatar;
