/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Header from "../Dashboard/Header/Header";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import "./createdExam.css";
import config from "../../config";
import { Link } from "react-router-dom";

export default function GetQuestionByExamName() {
  const { exam_id } = useParams();
  const [data, setData] = useState([]);
  const [, setExamDetails] = useState({});
  const [subjectName, setSubjectName] = useState("");
  const [gradeName, setGradeName] = useState("");
  const [examName, setExamName] = useState("");
  const [durationName, setDurationName] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [expire_date, setDate] = useState("");
  const [note, setNote] = useState("");

  const fetchData = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("User not authenticated.");
      return;
    }

    try {
      const examData = await getExamDetails(token);
      setExamDetails(examData);
      await fetchRelatedNames(token, examData);
      const tempData = await getQuestions(token);
      setData(tempData);
      console.log(JSON.stringify(tempData));
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  }, [exam_id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getExamDetails = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/exam/${exam_id}`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data.createExam;
    } catch (error) {
      console.log("Error fetching exam data:", error);
      throw error;
    }
  };

  const fetchRelatedNames = async (token, examData) => {
    try {
      const [subjectRes, gradeRes, durationRes, examRes] = await Promise.all([
        axios.get(`${config.apiUrl}/v1/web/api/subject/${examData.subject}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.apiUrl}/v1/web/api/grade/${examData.grade}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.apiUrl}/v1/web/api/duration/${examData.duration}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.apiUrl}/v1/web/api/exam/${exam_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      setSubjectName(subjectRes.data.subject.subjectName || "");
      setGradeName(gradeRes.data.grade.gradeName || "");
      setDurationName(durationRes.data.duration.addDuration || "");
      setExamName(examRes.data.createExam.examName || "");
      setPinCode(examRes.data.createExam.pinCode || "");
      setDate(examRes.data.createExam.expire_date || "");
      setNote(examRes.data.createExam.note || "");
    } catch (error) {
      console.log("Error fetching related names:", error);
      throw error;
    }
  };

  const getQuestions = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/question/examId/${exam_id}`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data?.data || [];
    } catch (error) {
      console.log("Error fetching Question data:", error);
      throw error;
    }
  };

  const removeQuestion = async (questionId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated. Redirecting to login page.");
        return;
      }

      const isConfirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this Question?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed.isConfirmed) {
        const finalURL = `${config.apiUrl}/v1/web/api/question/${questionId}`;
        await axios.delete(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        Swal.fire("Deleted!", "The Question has been deleted.", "success").then(
          () => {
            fetchData();
          }
        );
      }
    } catch (error) {
      console.log("Error deleting Question:", error);
      Swal.fire("Error!", "Failed to delete the Question.", "error");
    }
  };

  const StatusColor = (status) => {
    let color;
    if (status === "active") {
      color = "#28a745";
    } else {
      color = "tomato";
    }
    return color;
  };

  const renderQuestionTitle = (title) => {
    const maxLength = 20;

    if (title.length > maxLength) {
      return { __html: title.substring(0, maxLength) + "..." };
    } else {
      return { __html: title };
    }
  };

  return (
    <div>
      <Header />
      <div className="container" id="GetQestionContainer">
        <div id="examDetails" className="exam-details">
          <div className="detail-item">
            <strong id="examhHeaderName">Exam Name:</strong>
            <span id="examDataName"> {examName} </span>
          </div>
          <div className="detail-item">
            <strong id="examhHeaderName">Subject:</strong>
            <span id="examDataName"> {subjectName} </span>
          </div>
          <div className="detail-item">
            <strong id="examhHeaderName">Duration:</strong>
            <span id="examDataName"> {durationName} </span>
          </div>
          <div className="detail-item">
            <strong id="examhHeaderName">Grade:</strong>
            <span id="examDataName"> {gradeName} </span>
          </div>
          <div className="detail-item">
            <strong id="examhHeaderName">Pin Code:</strong>
            <span id="examDataName"> {pinCode} </span>
          </div>
          <div className="detail-item">
            <strong id="examhHeaderName">Expire Date:</strong>
            <span id="examDataName">
              {" "}
              {new Date(expire_date).toLocaleDateString()}{" "}
            </span>
          </div>
          <div className="detail-item">
            <strong id="examhHeaderName">Note:</strong>
            <span id="examDataName"> {note} </span>
          </div>
        </div>

        <Link
          to={`/add-questions?examId=${exam_id}&examName=${examName}&subject=${subjectName}&grade=${gradeName}`}
        >
          <button className="btn mb-3" id="submitBtn">
            Add Question
          </button>
        </Link>

        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">
                <i className="fas fa-list"></i>
              </th>
              <th scope="col" id="headName">
                Question Type
              </th>
              <th scope="col" id="headName">
                Question Title
              </th>
              <th scope="col" id="headName">
                Status
              </th>
              <th scope="col" id="headName">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {data.map((question, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td id="supplier">{question.questionType}</td>
                <td
                  id="supplier"
                  dangerouslySetInnerHTML={renderQuestionTitle(
                    question.questionTitle
                  )}
                ></td>
                <td
                  id="supplier"
                  style={{ color: StatusColor(question.status) }}
                >
                  {question.status}
                </td>
                <td>
                  <Link
                    className="btn"
                    id="editBtn"
                    to={`/edit/question/${question._id}`}
                  >
                    <i className="fas fa-edit" id="editIcon"></i>
                  </Link>
                  &nbsp;
                  <button
                    className="btn"
                    id="editDelete"
                    onClick={() => removeQuestion(question._id)}
                  >
                    <i className="fas fa-trash-alt" id="DeleteIcon"></i>&nbsp;
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to={`/question/${question._id}`} id="view">
                    VIEW
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
