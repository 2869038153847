import React, { useState, useEffect } from "react";
import Header from "../Dashboard/Header/Header";
import "./createExam.css";
import axios from "axios";
import config from "../../config";

export default function CreateExam() {
  const [data, setData] = useState({
    examName: "",
    subject: "",
    grade: "",
    duration: "",
    pinCode: "",
    addedBy: localStorage.getItem("userId"),
    expire_date: "",
    note: "",
    subjectsList: [],
    gradesList: [],
    durationsList: [],
    examId: [],
  });

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("User not authenticated.");
          return;
        }

        const finalURL = `${config.apiUrl}/v1/web/api/subject/`;
        const res = await axios.get(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData((prevState) => ({
          ...prevState,
          subjectsList: res.data.subjects || [],
        }));
      } catch (error) {
        console.log("Error fetching subject data:", error);
      }
    };

    fetchSubjects();
  }, []);

  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("User not authenticated.");
          return;
        }

        const finalURL = `${config.apiUrl}/v1/web/api/grade/`;
        const res = await axios.get(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData((prevState) => ({
          ...prevState,
          gradesList: res.data.grades || [],
        }));
      } catch (error) {
        console.log("Error fetching grades data:", error);
      }
    };

    fetchGrades();
  }, []);

  useEffect(() => {
    const fetchDuration = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("User not authenticated.");
          return;
        }

        const finalURL = `${config.apiUrl}/v1/web/api/duration/`;
        const res = await axios.get(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData((prevState) => ({
          ...prevState,
          durationsList: res.data.durations || [],
        }));
      } catch (error) {
        console.log("Error fetching subject data:", error);
      }
    };

    fetchDuration();
  }, []);

  function handleChange(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    setData({
      ...data,
      [name]: value,
    });
  }

  const onSubmitForm = async (e) => {
    try {
      e.preventDefault();

      const selectedSubject = data.subjectsList.find(
        (subject) => subject._id === data.subject
      );
      const selectedGrade = data.gradesList.find(
        (grade) => grade._id === data.grade
      );

      const subjectName = selectedSubject ? selectedSubject.subjectName : "";
      const gradeName = selectedGrade ? selectedGrade.gradeName : "";

      const token = localStorage.getItem("token");

      const res = await axios({
        method: "post",
        baseURL: config.apiUrl,
        url: "/v1/web/api/exam/create",
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(res.data);
      const examId = res.data.examId;
      const userId = localStorage.getItem("userId");
      console.log("userId:", userId);
      window.location.assign(
        `/add-questions?examName=${data.examName}&subject=${subjectName}&grade=${gradeName}&examId=${examId}`
      );
    } catch (error) {
      console.error(error);
    }
  };
  const generatePinCode = () => {
    const pinCode = Math.floor(100000 + Math.random() * 900000).toString();
    setData({
      ...data,
      pinCode: pinCode,
    });
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }

  return (
    <div>
      <Header />
      <div className="container" id="createExamContainer">
        <form onSubmit={(e) => onSubmitForm(e)}>
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="form_name" id="ExamHeader">
                <b>Exam name</b>
              </label>
              <input
                id="form_name"
                type="text"
                name="examName"
                className="form-control"
                placeholder="Enter exam name"
                required="required"
                value={data.examName}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-3" id="createExamCol">
              <label htmlFor="form_name" id="ExamHeader">
                <b>Subject</b>
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                id="form_name"
                value={data.subject}
                onChange={handleChange}
                name="subject"
              >
                <option selected>Select subject</option>
                {data.subjectsList.map((subject) => (
                  <option
                    key={subject._id}
                    value={subject._id}
                    name={subject.subjectName}
                  >
                    {subject.subjectName}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-3" id="createExamCol">
              <label htmlFor="form_name" id="ExamHeader">
                <b>Grade</b>
              </label>
              <select
                class="form-select"
                aria-label="Default select example"
                id="form_name"
                value={data.grade}
                onChange={handleChange}
                name="grade"
              >
                <option selected>Select grade</option>
                {data.gradesList.map((grade) => (
                  <option
                    key={grade._id}
                    value={grade._id}
                    name={grade.gradeName}
                  >
                    {grade.gradeName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row" id="CreateExamRow2">
            <div className="col-md-3">
              <label htmlFor="form_name" id="ExamHeader">
                <b>Exam duration</b>
              </label>
              <select
                class="form-select"
                aria-label="Default select example"
                id="form_name"
                value={data.duration}
                onChange={handleChange}
                name="duration"
              >
                <option selected>Select duration</option>
                {data.durationsList.map((duration) => (
                  <option
                    key={duration._id}
                    value={duration._id}
                    name={duration.addDuration}
                  >
                    {duration.addDuration}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3" id="createExamCol">
              <label htmlFor="form_name" id="ExamHeader">
                <b>Pin Code</b>
              </label>
              <div className="input-group" id="form_name">
                <input
                  type="text"
                  className="form-control"
                  id="autoSizingInputGroup"
                  placeholder="Pin Code"
                  value={data.pinCode}
                  name="pinCode"
                  readOnly
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={generatePinCode}
                >
                  Generate
                </button>
              </div>

              <div id="formSubtitle">
                (You can generate a your own pin code)
              </div>
            </div>
            <div className="col-md-3" id="createExamCol">
              <label htmlFor="form_name" id="ExamHeader">
                <b>Expire Date</b>
              </label>
              <input
                id="form_name"
                type="date"
                name="expire_date"
                className="form-control"
                required="required"
                value={data.expire_date}
                onChange={handleChange}
                min={getCurrentDate()}
              />
            </div>
          </div>
          <div className="row" style={{marginTop:"1%"}} >
          <div className="col-md-3" >
              <label htmlFor="form_name" id="ExamHeader">
                <b>Additinal Note</b>
              </label>
              <input
                id="form_name"
                type="test"
                name="note"
                className="form-control"
                placeholder="Enter note"
                value={data.note}
                onChange={handleChange}
              />
            </div>
          </div>

          <div id="ButtonSection">
            <div className="row" id="createExamRow3">
              <div className="col">
                <button
                  className="btn"
                  id="CreateExamBtnCancel"
                  onClick={() => window.history.back()}
                  type="button"
                >
                  Cancel
                </button>
              </div>

              <div className="col">
                <a href="#!">
                  <button
                    className="btn"
                    type="submit"
                    value="Submit"
                    id="CreateExamBtnNext"
                  >
                    Next
                  </button>
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
