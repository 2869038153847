import React, { useState, useEffect } from "react";
import "./account.css";
import Header from "../Dashboard/Header/Header";
import TabComponent from "../TabComponent/TabComponent";
import config from "../../config";
import axios from "axios";
import Swal from "sweetalert2";

export default function Account() {
  const userId = localStorage.getItem("userId");
  const [teacher, setTeacher] = useState();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${config.apiUrl}/v1/web/api/teacher/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeacher(res.data.teacher);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [userId, token]);

  function handleChange(evt) {
    const { name, value } = evt.target;
    if (name === "currentPassword") {
      setCurrentPassword(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmNewPassword") {
      setConfirmNewPassword(value);
    } else {
      setTeacher({
        ...teacher,
        [name]: value,
      });
    }
  }

  const validatePasswordStrength = () => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);

    if (
      newPassword.length < minLength ||
      !hasUpperCase ||
      !hasLowerCase ||
      !hasNumber ||
      !hasSpecialChar
    ) {
      Swal.fire({
        icon: "error",
        title: "Weak Password",
        text: `Password must be at least ${minLength} characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.`,
      });
      return false;
    }
    return true;
  };

  const validatePhoneNumber = () => {
    const phoneNumberPattern = /^\d{10}$/;
    if (!phoneNumberPattern.test(teacher.telephone)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Phone Number",
        text: "Phone number must be a 10-digit number. (Ex: '070xxxxxxx'). ",
      });
      return false;
    }
    return true;
  };

  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(teacher.userName)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email Address",
        text: "Email address must be in the format 'user@gmail.com'.",
      });
      return false;
    }
    return true;
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (!validatePhoneNumber() || !validateEmail()) {
      return;
    }

    try {
      await axios.put(
        `${config.apiUrl}/v1/web/api/teacher/update/${userId}`,
        teacher,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Updated Successfully!",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitFormPassword = async (e) => {
    e.preventDefault();

    if (!validatePasswordStrength()) {
      return;
    }

    if (newPassword !== confirmNewPassword) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "New passwords do not match.",
      });
      return;
    }

    try {
      await axios.put(
        `${config.apiUrl}/v1/web/api/teacher/change-password/${userId}`,
        { currentPassword, newPassword, confirmNewPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Password Updated Successfully!",
      }).then(() => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        window.location.assign("/");
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error.response?.data?.message || "Error updating password.",
      });
    }
  };

  if (!teacher) {
    return;
  }

  return (
    <div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Change Password
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form id="contact-form" onSubmit={onSubmitFormPassword}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="current_password">
                      <b>Current Password</b>
                    </label>
                    <div className="position-relative">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        id="form2Example2"
                        className="form-control"
                        name="currentPassword"
                        onChange={handleChange}
                        placeholder="Enter your password"
                        style={{ paddingRight: "50px" }}
                      />
                      <button
                        type="button"
                        id="visible"
                        className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
                        onClick={togglePasswordVisibility}
                        style={{ right: "10px" }}
                      >
                        {passwordVisible ? (
                          <i className="bi bi-eye-slash"></i>
                        ) : (
                          <i className="bi bi-eye"></i>
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6 mb-3">
                    <label htmlFor="new_password">
                      <b>New Password</b>
                    </label>
                    <div className="position-relative">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        id="form2Example2"
                        className="form-control"
                        name="newPassword"
                        onChange={handleChange}
                        placeholder="Enter your password"
                        style={{ paddingRight: "50px" }}
                      />
                      <button
                        type="button"
                        id="visible"
                        className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
                        onClick={togglePasswordVisibility}
                        style={{ right: "10px" }}
                      >
                        {passwordVisible ? (
                          <i className="bi bi-eye-slash"></i>
                        ) : (
                          <i className="bi bi-eye"></i>
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6 mb-3">
                    <label htmlFor="confirm_new_password">
                      <b>Confirm New Password</b>
                    </label>
                    <div className="position-relative">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        id="form2Example2"
                        className="form-control"
                        name="confirmNewPassword"
                        onChange={handleChange}
                        placeholder="Enter your password"
                        style={{ paddingRight: "50px" }}
                      />
                      <button
                        type="button"
                        id="visible"
                        className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
                        onClick={togglePasswordVisibility}
                        style={{ right: "10px" }}
                      >
                        {passwordVisible ? (
                          <i className="bi bi-eye-slash"></i>
                        ) : (
                          <i className="bi bi-eye"></i>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                &nbsp;
                <button
                  type="submit"
                  value="Submit"
                  className="btn"
                  id="saveChangesBtn"
                >
                  Save changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Header />
        <div>
          <div id="tabComponent">
            <TabComponent />
          </div>
          <div className="container" id="container">
            <form onSubmit={onSubmitForm} id="Contactform">
              <div className="row">
                <div className="col" id="ImageCol">
                  <img src="images/ProfilePicture.png" alt="logo" />
                </div>
                <div className="col" id="BtnCol">
                  <button
                    id="profileBtn"
                    type="submit"
                    value="Submit"
                    className="btn"
                  >
                    Edit Profile
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb-3">
                  <label htmlFor="form_name">
                    <b>Full Name</b>
                  </label>
                  <input
                    id="form_name"
                    type="text"
                    className="form-control"
                    placeholder="Enter your Full Name"
                    required="required"
                    defaultValue={teacher.Name}
                    onChange={handleChange}
                    name="Name"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb-3">
                  <label htmlFor="form_lastname">
                    <b>Phone Number</b>
                  </label>
                  <input
                    id="form_lastname"
                    type="text"
                    className="form-control"
                    placeholder="Enter your Phone Number"
                    required="required"
                    defaultValue={teacher.telephone}
                    onChange={handleChange}
                    name="telephone"
                  />
                </div>
              </div>
              <div className="mb-3">
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb-4">
                    <label htmlFor="form_email">
                      <b>Email</b>
                    </label>
                    <input
                      id="form_email"
                      type="text"
                      className="form-control"
                      placeholder="Enter your email"
                      required="required"
                      defaultValue={teacher.userName}
                      onChange={handleChange}
                      name="userName"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb-4" id="changeLink">
                    <div id="Change">
                      <a
                        href="#1"
                        id="changePassword"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <i className="fas fa-lock"></i>&nbsp;Change Password
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
