import React, { useState, useEffect } from "react";
import Header from "../Admin Dashboard/Header/Header";
import "./payments.css";
import config from "../../config";
import axios from "axios";

export default function Payments() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        alert("User not authenticated.");
        return;
      }

      try {
        const tempData = await getData(token);
        setData(tempData);
        setFilteredData(tempData);
      } catch (error) {
        console.log("Error fetching duration data:", error);
      }
    };
    fetchData();
  }, []);

  const getData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/purchase//allPurchase`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const purchase = res.data?.purchases || [];

      return purchase;
    } catch (error) {
      console.log("Error fetching purchase data:", error);
      throw error;
    }
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (query) {
      const filtered = data.filter((purchase) =>
        purchase.teacherId.Name.toLowerCase().includes(query)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-LK", {
      style: "currency",
      currency: "LKR",
    }).format(amount);
  };

  return (
    <div>
      <div>
        <Header />

        <div>
          <div className="container" id="PaymentContainer">
            <div className="row" id="RowCol">
              <div className="col">
                <div className="col-lg-3 mt-2 mb-2">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search for Payment"
                    name="serchQuery"
                    id="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>

            <table className="table table-hover" id="table">
              <thead>
                <tr>
                  <th scope="col">
                    <i className="fas fa-list"></i>
                  </th>
                  <th scope="col" id="tableRow">
                    Teacher name
                  </th>
                  <th scope="col" id="tableRow">
                    Date
                  </th>
                  <th scope="col" id="tableRow">
                    Order ID
                  </th>
                  <th scope="col" id="tableRow">
                    Amount
                  </th>
                  <th scope="col" id="tableRow">
                    Credits
                  </th>
                  <th scope="col" id="tableRow">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody id="tbody">
                {currentItems.map((purchase, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td id="paymentBody">
                      <img
                        src="/images/teacher.png"
                        alt="logo"
                        id="teacherProfile"
                      />
                      &nbsp;&nbsp;
                      {purchase.teacherId.Name}
                    </td>
                    <td id="paymentBody">
                      {" "}
                      {new Date(purchase.date).toLocaleDateString()}
                    </td>
                    <td id="paymentBody">{purchase.orderId}</td>
                    <td id="supplier">{formatCurrency(purchase.amount)}</td>
                    <td id="paymentBody">{purchase.noOfCredit}</td>
                    <td id="status">
                      {" "}
                      <span id="status">{purchase.status}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div id="paginationTeacher">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#1"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </a>
                  </li>
                  {[...Array(totalPages).keys()].map((number) => (
                    <li
                      key={number + 1}
                      className={`page-item ${
                        currentPage === number + 1 ? "active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#1"
                        onClick={() => handlePageChange(number + 1)}
                      >
                        {number + 1}
                      </a>
                    </li>
                  ))}
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#1"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
