import React from "react";
import Header from "../Admin Dashboard/Header/Header";
import "./addNew.css";
import { Link } from "react-router-dom";

export default function addNew() {
  return (
    <div>
      <Header />
      <div className="container" id="addNewContainer">
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Add Subject Names</h5>
                <p class="card-text">
                  Discover new subjects to enrich your academic journey.
                </p>
                <Link to="/add-subject" class="btn " id="submitBtn">
                  Add Subject
                </Link>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Add the Grades</h5>
                <p class="card-text">
                  Explore the possibilities below to enhance your academic
                  journey.
                </p>
                <Link to="/add-grade" class="btn " id="submitBtn">
                  Add Grade
                </Link>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Add the Exam Duration</h5>
                <p class="card-text">
                  Get ready for success by setting the duration for your exams.
                </p>
                <Link to="/add-duration" class="btn " id="submitBtn">
                  Add Exam Duration
                </Link>
              </div>
            </div>
          </div>
        </div><br/>

        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Update Credit</h5>
                <p class="card-text">
                  Update teacher's default credit value.
                </p>
                <Link to="/update-defaultPoint" class="btn " id="submitBtn">
                  Update Credit
                </Link>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  );
}
