import React, { useState, useEffect } from "react";
import Header from "../Dashboard/Header/Header";
import "./createdExam.css";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config";
import { Link } from "react-router-dom";

export default function CreatedExamList() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getData(token);
        setData(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching subject data:", error);
      }
    };
    fetchData();
  }, []);

  const getData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/exam/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const createExams = res.data?.createExams || [];
      const userId = localStorage.getItem("userId");

      return createExams.filter((exam) => exam.addedBy === userId);
    } catch (error) {
      console.log("Error fetching Subject data:", error);
      throw error;
    }
  };

  const removeExam = async (examId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated. Redirecting to login page.");

        return;
      }

      const isConfirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this Exam?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed.isConfirmed) {
        const finalURL = `${config.apiUrl}/v1/web/api/exam/${examId}`;
        await axios.delete(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        Swal.fire("Deleted!", "The Question has been deleted.", "success").then(
          () => {
            window.location.reload("false");
          }
        );
      }
    } catch (error) {
      console.log("Error deleting Question:", error);
      Swal.fire("Error!", "Failed to delete the Question.", "error");
    }
  };

  

  return (
    <div>
      <Header />
      <div className="container" id="CreatedExamContainer">
        <div className="row">
          {data.map((createExam, index) => (
            <div className="col-md-3 mb-5" key={index}>
              <div className="card" key={index} id="examCard1">
                <div className="card-body" id="reportBody">
                  <Link to={`/get-question-by-examid/${createExam._id}`}>
                    <button id="button">
                      <img
                        src="/images/Group87593.png"
                        alt="logo"
                        id="reportLogo"
                      />
                      &nbsp;&nbsp;
                      <h5 className="card-title" id="reportTitle">
                      {createExam.examName ? createExam.examName.split(' ')[0] : ''}
                      </h5>
                    </button>
                  </Link>
                  <button
                    className="btn"
                    id="editDelete"
                    style={{ marginTop: "8%", marginLeft: "1.5%" }}
                    onClick={() => removeExam(createExam._id)}
                  >
                    <i className="fas fa-trash-alt" id="DeleteIcon"></i>&nbsp;
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
