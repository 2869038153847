import React, { useRef, useEffect } from "react";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const EmotionBarChart = ({ emotionCounts }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      chartInstance.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["Happy", "Sad", "Neutral", "Surprise"],
          datasets: [
            {
              label: "Count",
              data: [
                emotionCounts.happy,
                emotionCounts.sad,
                emotionCounts.neutral,
                emotionCounts.surprise,
              ],
              backgroundColor: ["#4DB6AC", "#F65656", "#FFA216", "#9747FF"],
              borderColor: ["#4DB6AC", "#F65656", "#FFA216", "#9747FF"],
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: "index",
              intersect: false,
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                color: "#333",
                font: {
                  weight: 1000,
                },
              },
              grid: {
                color: "rgba(0, 0, 0, 0.1)",
              },
              ticks: {
                color: "#333",
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                color: "#333",
                font: {
                  weight: 1000,
                },
              },
              grid: {
                color: "rgba(0, 0, 0, 0.1)",
              },
              ticks: {
                color: "#333",
              },
            },
          },
          animation: {
            duration: 1000,
            easing: "easeInOutQuart",
          },
        },
      });

      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }
  }, [emotionCounts]);

  return <canvas ref={chartRef} />;
};

export default EmotionBarChart;
