import React, { useState } from "react";
import "./login.css";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config";
import { Link } from "react-router-dom";

export default function Login() {
  const [data, setData] = useState({
    userName: "",
    password: "",
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  function handleChange(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    setData({
      ...data,
      [name]: value,
    });
  }

  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(data.userName)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email Address",
        text: "Email address must be in the format 'user@gmail.com'.",
      });
      return false;
    }
    return true;
  };

  const onSubmitForm = async (e) => {
    console.log(config.apiUrl);

    if (!validateEmail()) {
      return;
    }

    try {
      e.preventDefault();

      const res = await axios({
        method: "post",
        baseURL: config.apiUrl,
        url: "/v1/web/api/user/signin",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });

      const token = res.data.token;
      console.log(res.data.existingUser._id);
      localStorage.setItem("token", token);
      localStorage.setItem("userId", res.data.existingUser._id);

      if (res.data.existingUser.status === "inactive") {
        Swal.fire({
          icon: "error",
          title: "Account Inactive",
          text: "Your account is currently inactive. Please contact support for assistance.",
        });
        return;
      }

      Swal.fire({
        icon: "success",
        title: "Login Successful!",
        text: "Redirecting to Dashboard...",
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (res.data.existingUser.type === "teacher") {
          window.location.assign("/dashboard");
        } else {
          window.location.assign("/admin-dashboard");
        }
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while signing in. Please try again.",
      });
    }
  };

  return (
    <div  id="logindev">
      <section className=" text-center text-lg-start">
        <div className="card mb-3">
          <div className="row g-0 d-flex align-items-center">
            <div className="col-md-8">
              <img
                src="/images/loginBg.png"
                alt="login background"
                className="background-image"
              />

              <div className="overlayText">
                <h1>Recognize student's facial expressions during the exams</h1>
                <p>
                  Facial Expression Recognition System (FERS) tailored to
                  recognize students' facial expressions
                </p>
              </div>
            </div>

            {/*form */}

            <div className="col" id="formDiv">
              <div id="new1">
                <div id="fedesLogo">
                  <img src="/images/face-detection.png" alt="logo" />
                </div>
                <div id="faceCapture">
                  <span id="faceName">Face Capture</span>
                </div>
              </div>

              <div id="loginTextDiv">
                <span id="loginText">Log In to your account</span>
              </div>
              <div className="card-body py-5 px-md-5" id="new">
                <form onSubmit={(e) => onSubmitForm(e)}>
                  <div className="form-outline mb-3">
                    <label
                      className="form-label"
                      for="form2Example1"
                      id="lableForm"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      id="form2Example1"
                      className="form-control"
                      name="userName"
                      value={data.userName}
                      onChange={handleChange}
                      placeholder="Enter your email address"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      title="Please enter a valid email address (e.g., user@example.com)"
                    />
                  </div>

                  <div className="form-outline mb-5">
                    <label
                      className="form-label"
                      for="form2Example2"
                      id="lableForm"
                    >
                      Password
                    </label>
                    <div className="position-relative">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        id="form2Example2"
                        className="form-control"
                        name="password"
                        value={data.password}
                        onChange={handleChange}
                        placeholder="Enter your password"
                        style={{ paddingRight: "50px" }}
                      />
                      <button
                        type="button"
                        id="visible"
                        className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
                        onClick={togglePasswordVisibility}
                        style={{ right: "10px" }}
                      >
                        {passwordVisible ? (
                          <i className="bi bi-eye-slash"></i>
                        ) : (
                          <i className="bi bi-eye"></i>
                        )}
                      </button>
                    </div>
                  </div>

                  <button
                    type="submit"
                    value="Login"
                    className="btn btn-block mb-2"
                    id="login"
                  >
                    Log In
                  </button>

                  <div className="row mb-4">
                    <div className="col">
                      <span id="signin">Don’t you have an account?</span>
                      <Link to={config.register} id="signup">
                        Sign Up
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
