const navList = [
  {
    _id: 1,
    name: "Add New",
    icon: "bi bi-plus-lg",
    href: "/add-new",
  },
  {
    _id: 2,
    name: "Dashboard",
    icon: "bi bi-house-door",
    href: "/admin-dashboard",
  },
  {
    _id: 3,
    name: "Banners",
    icon: "bi bi-file-earmark-image",
    href: "/add-banner",
  },
  {
    _id: 4,
    name: "Teachers",
    icon: "bi bi-person",
    href: "/teachers",
  },

  {
    _id: 5,
    name: "Students",
    icon: "bi bi-mortarboard",
    href: "/students",
  },
  {
    _id: 6,
    name: "Created Exams",
    icon: "bi bi-file-earmark-text",
    href: "/admin-create-exam-list",
  },

  {
    _id: 7,
    name: "Payments",
    icon: "bi bi-credit-card",
    href: "/admin/payments",
  },

  {
    _id: 8,
    name: "Emotion Images",
    icon: "bi bi-card-image",
    href: "/emotion-images",
  },
];

export default navList;
