import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import Swal from "sweetalert2";
import Header from "../Dashboard/Header/Header";
import "./style.css";
import CreatedExamList from "./CreatedExamList";

export default function AddCreatedExam() {
  const [data, setData] = useState({
    examName: "",
    pinCode: "",
    expire_date: "",
    addedby: localStorage.getItem("userId"),
    note: "",
    examList: [],
  });

  useEffect(() => {
    const fetchExams = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("User not authenticated.");
          return;
        }

        const userId = localStorage.getItem("userId");
        const finalURL = `${config.apiUrl}/v1/web/api/exam/`;

        const res = await axios.get(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const createExams = res.data.createExams || [];

        const userExams = createExams.filter((exam) => exam.addedBy === userId);

        setData((prevState) => ({
          ...prevState,
          examList: userExams,
        }));
      } catch (error) {
        console.log("Error fetching exam data:", error);
      }
    };

    fetchExams();
  }, []);

  function handleChange(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    setData({
      ...data,
      [name]: value,
    });
  }

  const onSubmitForm = async (e) => {
    try {
      e.preventDefault();

      const token = localStorage.getItem("token");

      const res = await axios({
        method: "post",
        baseURL: config.apiUrl,
        url: "/v1/web/api/create-exam",
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(res.data);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data Saved Successfully!",
      }).then(() => {
        window.location.reload(false);
      });
    } catch (error) {
      console.log(error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while saving the data. Please try again.",
      });
    }
  };

  const generatePinCode = () => {
    const pinCode = Math.floor(100000 + Math.random() * 900000).toString();
    setData({
      ...data,
      pinCode: pinCode,
    });
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }

  return (
    <div>
      <Header />
      <div className="container" id="modifyPinContainer">
        <form
          className="row gy-2 gx-3 align-items-center"
          onSubmit={(e) => onSubmitForm(e)}
        >
          <div className="col-auto">
            <select
              className="form-select"
              id="autoSizingSelect"
              value={data.examName}
              name="examName"
              onChange={handleChange}
            >
              <option selected>Select Exam Name</option>
              {data.examList.map((createExams) => (
                <option
                  key={createExams._id}
                  value={createExams._id}
                  name={createExams.examName}
                >
                  {createExams.examName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-auto">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="autoSizingInputGroup"
                placeholder="Pin Code"
                value={data.pinCode}
                name="pinCode"
                disabled
                onChange={handleChange}
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={generatePinCode}
              >
                Generate
              </button>
            </div>
          </div>

          <div className="col-auto">
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                id="autoSizingInputGroup"
                name="expire_date"
                value={data.expire_date}
                onChange={handleChange}
                min={getCurrentDate()}
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="autoSizingInputGroup"
                name="note"
                placeholder="Additional Note"
                value={data.note}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-auto">
            <button type="submit" className="btn" id="submitBtn">
              Submit
            </button>
          </div>
        </form>
        <CreatedExamList />
      </div>
    </div>
  );
}
