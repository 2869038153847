/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Header from "../Admin Dashboard/Header/Header";
import "./index.css";
import axios from "axios";
import config from "../../config";

export default function Index() {
  const [exam, setExam] = useState([]);
  const [student, setStudent] = useState([]);
  const [teacher, setTeacher] = useState([]);
  const [facedStudent, setFacedStudent] = useState([]);

  useEffect(() => {
    const fetchExamData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getExamData(token);
        setExam(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching exam data:", error);
      }
    };
    fetchExamData();
  }, []);

  const getExamData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/exam/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const createExams = res.data?.createExams || [];

      return createExams;
    } catch (error) {
      console.log("Error fetching Subject data:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getStudentData(token);
        setStudent(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching student data:", error);
      }
    };
    fetchStudentData();
  }, []);

  const getStudentData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/student/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const students = res.data?.students || [];

      return students;
    } catch (error) {
      console.log("Error fetching Subject data:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchTeacherData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getTeacherData(token);
        setTeacher(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching teacher data:", error);
      }
    };
    fetchTeacherData();
  }, []);

  const getTeacherData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/teacher/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const teachers = res.data?.teachers || [];

      return teachers;
    } catch (error) {
      console.log("Error fetching Subject data:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchFacedStudentData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getFacedStudentData(token);
        const aggregatedData = aggregateFacedStudentData(tempData);
        setFacedStudent(aggregatedData);
        console.log(JSON.stringify(aggregatedData));
      } catch (error) {
        console.log("Error fetching faced student data:", error);
      }
    };
    fetchFacedStudentData();
  }, []);

  const getFacedStudentData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/image/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const imotions = res.data?.imotions || [];
      return imotions;
    } catch (error) {
      console.log("Error fetching imotions data:", error);
      throw error;
    }
  };

  const aggregateFacedStudentData = (data) => {
    const aggregated = {};

    data.forEach((item) => {
      const { userId, ...rest } = item;

      if (!aggregated[userId]) {
        aggregated[userId] = [];
      }

      aggregated[userId].push(rest);
    });

    return Object.entries(aggregated).map(([userId, records]) => ({
      userId,
      records,
    }));
  };

  const studentValue = 300;
  const examValue = 150;
  const facedStudentValue = 300;

  const numberOfExams = exam.length + examValue;
  const numberOfStudent = student.length + studentValue;
  const numberOfTeacher = teacher.length;
  const numberOfFacedStudentr = facedStudent.length + facedStudentValue;

  return (
    <div>
      <Header />
      <div className="container" id="cardContainer">
        <h3 id="statictise">Statictise</h3>
        <div className="row" id="row">
          <div className="col-md-2" id="card">
            <div className="card" id="Admincard1">
              <div className="card-body">
                <h5 className="card-title" id="cardTtile">
                  TOTAL EXAMS CREATED
                </h5>
                <p className="card-text" id="cardText">
                  {numberOfExams}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-2" id="card">
            <div className="card" id="Admincard1">
              <div className="card-body">
                <h5 className="card-title" id="cardTtile">
                  TOTAL STUDENTS
                </h5>
                <p className="card-text" id="cardText">
                  {numberOfStudent}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-2" id="card">
            <div className="card" id="Admincard1">
              <div className="card-body">
                <h5 className="card-title" id="cardTtile">
                  TOTAL TEACHERS
                </h5>
                <p className="card-text" id="cardText">
                  {numberOfTeacher}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-2" id="card">
            <div className="card" id="Admincard1">
              <div className="card-body">
                <h5 className="card-title" id="cardTtile">
                  TOTAL NO. OF STUDENTS FACED
                </h5>
                <p className="card-text" id="cardText">
                  {numberOfFacedStudentr}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Pending Request */}
        {/* <div className="row" id="pendingReruestRow">
            <div className="col">
              <span id="pendingHeader">Pending registrations</span>
            </div>
            <div className="col">
              <div id="btnClearAll">
                <span id="clearAll">CLEAR ALL</span>
              </div>
            </div>
          </div> */}

        {/* Table */}
        {/* <table className="table" id="PendingRequestTable">
            <tbody>
              <tr>
                <div id="tableRow1">
                  <th>
                    <img
                      src="/images/Group87670.png"
                      alt="logo"
                      id="pendingLogo"
                    ></img>
                    &nbsp;
                    <span id="TeacherName">Dinesh Muthugala</span>{" "}
                    <span id="TeacherStatus">is requesting to join</span>
                    <br />
                    <span id="time">Just now</span>
                  </th>
                  <th className="align-middle">
                    <div id="btnAD">
                      <span id="approve">APPROVE</span>
                      <span id="decline">DECLINE</span>
                    </div>
                  </th>
                </div>
              </tr>
            </tbody>
          </table> */}
      </div>
    </div>
  );
}
