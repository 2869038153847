import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config/index";
import "./style.css";
import Swal from "sweetalert2";
import Header from "../Dashboard/Header/Header";

export default function BuyCredits() {
  const sriLankanCities = [
    "Colombo",
    "Kandy",
    "Galle",
    "Jaffna",
    "Negombo",
    "Batticaloa",
    "Matara",
    "Kurunegala",
    "Anuradhapura",
    "Polonnaruwa",
    "Ratnapura",
    "Kalutara",
    "Mulleriyawa",
    "Hambantota",
    "Nuwara Eliya",
    "Vavuniya",
    "Kilinochchi",
    "Trincomalee",
    "Ampara",
    "Monaragala",
    "Puttalam",
    "Badulla",
    "Matale",
    "Dambulla",
    "Kegalle",
    "Gampaha",
    "Dehiwala",
    "Moratuwa",
    "Wattala",
    "Sri Jayawardenepura Kotte",
    "Maharagama",
    "Boralesgamuwa",
    "Battaramulla",
    "Kelaniya",
    "Kaduwela",
    "Nawala",
    "Thalawathugoda",
  ];

  const generateRandomOrderId = () => {
    return Math.floor(10000000 + Math.random() * 90000000).toString();
  };

  const [hash, setHash] = useState("");
  const [creditAmount, setCreditAmount] = useState(0);
  const [orderId] = useState(generateRandomOrderId());
  const name = "Buy Credits";
  const amount = creditAmount;
  const teacherId = localStorage.getItem("userId");
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    country: "Sri Lanka",
  });

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${config.apiUrl}/v1/web/api/teacher/${teacherId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const teacherData = response.data.teacher;
        setData({
          firstName: teacherData.firstName,
          lastName: teacherData.lastName,
          email: teacherData.email,
          phone: teacherData.phone,
          address: teacherData.address,
          city: teacherData.city,
          country: teacherData.country,
        });
      } catch (error) {
        console.error("Error fetching teacher data:", error);
      }
    };

    fetchTeacherData();
  }, [teacherId]);

  function handleChange(evt) {
    const { name, value } = evt.target;
    setData({
      ...data,
      [name]: value,
    });
  }

  const savePurchaseData = async () => {
    try {
      const token = localStorage.getItem("token");

      const res = await axios.post(
        `${config.apiUrl}/v1/web/api/purchase/add`,
        {
          teacherId,
          noOfCredit: creditAmount,
          amount: creditAmount,
          orderId,
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(res.data);

      await axios.put(
        `${config.apiUrl}/v1/web/api/teacher/buyCredit/${teacherId}`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data Saved Successfully!",
      });
    } catch (error) {
      console.error("Error saving purchase data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while saving the data. Please try again.",
      });
    }
  };

  useEffect(() => {
    const fetchHash = async () => {
      try {
        const response = await axios.post(
          `${config.apiUrl}/v1/web/api/payment/generate-hash`,
          {
            merchant_id: `${config.merchant_id}`,
            amount,
            order_id: orderId,
            currency: "LKR",
            merchant_secret: `${config.merchant_secret}`,
          }
        );

        setHash(response.data.hash);
      } catch (error) {
        console.error("Error fetching hash:", error);
      }
    };

    if (amount > 0) {
      fetchHash();
    }
  }, [orderId, amount]);

  console.log("Hash", hash);

  const payment = {
    sandbox: true,
    merchant_id: `${config.merchant_id}`,
    merchant_secret: `${config.merchant_secret}`,
    return_url: "http://localhost:3000/payment",
    cancel_url: "http://localhost:3000/payment",
    notify_url: "http://localhost:3000/payment",
    order_id: orderId,
    items: name,
    amount,
    currency: "LKR",
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    phone: data.phone,
    address: data.address,
    city: data.city,
    country: data.country,
    hash,
  };

  window.payhere.onCompleted = function onCompleted(orderId) {
    savePurchaseData();
    console.log("Payment completed. OrderID:", orderId);
  };

  window.payhere.onDismissed = function onDismissed() {
    console.log("Payment dismissed");
  };

  window.payhere.onError = function onError(error) {
    console.log("Error:", error);
  };

  const pay = () => {
    window.payhere.startPayment(payment);
  };

  return (
    <div>
      <Header />
      <div className="container" id="buyCredit">
        <div className="payment-modal-container">
          <form onSubmit={(e) => e.preventDefault()} id="buyCreditForm">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="mb-3">
                    <b>First Name</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="form-control-id"
                    placeholder="Enter first name"
                    name="firstName"
                    value={data.firstName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="mb-3">
                    <b>Last Name</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="form-control-id"
                    placeholder="Enter last name"
                    name="lastName"
                    value={data.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="mb-3">
                    <b>Email address</b>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="form-control-id"
                    placeholder="Enter email"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="mb-3">
                    <b>Phone</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="form-control-id"
                    placeholder="Enter Phone Number"
                    name="phone"
                    value={data.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="mb-3">
                    <b>Address</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="form-control-id"
                    placeholder="Enter Address"
                    name="address"
                    value={data.address}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="mb-3">
                    <b>City</b>
                  </label>
                  <select
                    className="form-control"
                    id="form-control-id"
                    name="city"
                    value={data.city}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select City
                    </option>
                    {sriLankanCities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="mb-3">
                    <b>Country</b>
                  </label>
                  <select
                    className="form-control"
                    id="form-control-id"
                    name="country"
                    value={data.country}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select Country
                    </option>
                    <option value="Sri Lanka">Sri Lanka</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="mb-3">
                    <b>Credits Amount</b>
                  </label>
                  <input
                    type="number"
                    min={1}
                    max={5000}
                    className="form-control"
                    id="form-control-id"
                    placeholder="Enter credits amount"
                    value={creditAmount}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCreditAmount(value);
                      setData({
                        ...data,
                        noOfCredit: value,
                        amount: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="count-display">
              <label className="count-label">Total Amount:</label>
              <div className="count-value">LKR: {creditAmount}</div>
            </div>
          </form>
          <button
            style={{ marginTop: "6%" }}
            className="payhere-button"
            onClick={pay}
          >
            Pay with Payhere
          </button>
          <div id="formSubtitle">
            (If you have any issue with payment please contact +94770508710 via
            Whatsapp or direct)
          </div>
        </div>
      </div>
    </div>
  );
}
