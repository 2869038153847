import React from "react";
import "./Header.css";
import Logo from "./Logo";
import SideBar from "../Sidebar/SideBar";
import Nav from "./Nav/Nav";
import { useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();
  
  const pageNames = {
    "add-questions": "Add Questions",
    "physics-exam" : "Physics Exam",
    "create-exam" :  "Create Exam",
    "DashBoard" : "DashBoard",
    "report" : "Reports",
    "Art_Exam": "Art Exam",
    "History_Exam": "History Exam",
    "settings" : "Settings",
    "billing-information":"Billing Information",
    "created-exam":"Created Exam",
    "get-question-by-examid": "Questions",
    "question": "Question Details",
    "edit" : "Edit Questions",
    "add-pincode" : "Add Pin Code",
    "edit-createExam": "Edit Modify Pin Code",
    "report-generate": "Report Details",
    "payment": "Buy Credits",
    "purchase-history" : "Purchase History"
    
  };

  const currentPagePath = location.pathname.split("/")[1];
  const currentPageName = pageNames[currentPagePath] || "Dashboard";

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <Logo />&nbsp;&nbsp;&nbsp;
      <h5>{currentPageName}</h5>
      <SideBar />
      <Nav />
    </header>
  );
}

export default Header;
