import React, { Component } from "react";
import Header from "../Dashboard/Header/Header";
import "./Billing.css";
import TabComponent from "../TabComponent/TabComponent";

export default class BillingInformation extends Component {
  render() {
    return (
      <div>
        <Header />
        <div>
          <div id="tabComponent">
            <TabComponent />
          </div>
          <div className="container" id="BillingContainer">
            <div className="row">
              <div className="col-md-4" id="BillingCol">
                <a href="# ">
                  <div class="card" id="BillingCard1">
                    <div class="card-body">
                      <h5 class="card-title" id="AddCard">
                        <i class="bi bi-plus-square"></i>&nbsp;Add new card
                      </h5>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-md-4" id="BillingCol">
                <div class="card" id="BillingCard2">
                  <div class="card-body" id="BillingText">
                    <div className="primaryDiv">
                      <span id="primary">Primary</span>
                      <a href="# ">
                        &nbsp;&nbsp;
                        <i
                          class="bi bi-three-dots-vertical"
                          id="bi-three-dots-vertical"
                        ></i>
                      </a>
                    </div>
                    <h5 class="card-title" id="AddedCard">
                      <img src="/images/card.png" alt="logo" id="billingImg" />
                      &nbsp;Visa ending in 4567
                    </h5>
                    <p id="BillingSubtile">Expires 02/24</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" id="billingRow">
              <div id="BillingHistoryDiv">
                <h5 id="BillingHistory">Billing History</h5>
              </div>
              <table className="table table-hover" id="Billingtable">
                <thead>
                  <tr>
                    <th scope="col" id="tableRow">
                      <input type="checkbox" id="checkbox" name="checkbox" />
                      &nbsp;Period
                    </th>
                    <th scope="col" id="tableRow">
                      Total Amount
                    </th>
                    <th scope="col" id="tableRow">
                      Credits
                    </th>
                    <th scope="col" id="tableRow">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody id="tbody">
                  <tr>
                    <td>
                      <input type="checkbox" id="checkbox" name="checkbox" />
                      &nbsp;March 2024
                    </td>
                    <td>LKR. 3000.00</td>
                    <td>100</td>
                    <td>
                      <a
                        type="button"
                        class="btn btn-primary"
                        id="btnDownload"
                        href="#1"
                        style={{ border: "none" }}
                      >
                        <i class="bi bi-download" id="dowloadIcon"></i>
                      </a>
                      &nbsp;
                      <a className="btn" id="btnDelete" href="#1">
                        <i className="bi bi-trash" id="deleteIcon"></i>
                      </a>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <a href="# " id="view">
                        VIEW
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
