import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

const EmotionDownload = ({
  questionDetails,
  numberOfStudents,
  passedPercentage,
  failedPercentage,
}) => {
  const handleDownload = () => {
    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
    });

    const pageHeight = doc.internal.pageSize.height;
    let yPosition = 20;

    const addPageBorder = () => {
      doc.rect(6, 10, 200, 270);
    };

    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(40, 55, 71);
    doc.text("FEDES Report: Emotional Detection Results", 20, yPosition);
    yPosition += 10;

    const tableData = [
      ["Student Name", numberOfStudents],
      ["Passed Percentage", passedPercentage],
      ["Failed Percentage", failedPercentage],
    ];

    doc.autoTable({
      head: [["Attribute", "Value"]],
      body: tableData,
      startY: yPosition,
      theme: "striped",
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 90 },
      },
      didDrawPage: (data) => {
        yPosition = data.cursor.y + 10;
        addPageBorder();
      },
    });

    const stripHtml = (html) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || "";
    };

    const addQuestionDetails = (detail, index) => {
      if (yPosition + 50 > pageHeight) {
        doc.addPage();
        yPosition = 20;
        addPageBorder();
      }

      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text(`Question ${index + 1}:`, 20, yPosition);

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");

      yPosition += 10;
      doc.text(`${stripHtml(detail.questionTitle)}`, 20, yPosition);
      yPosition += 10;
      doc.text(`Answer: ${stripHtml(detail.answer)}`, 20, yPosition);
      yPosition += 10;
      doc.text(`Student Pass: ${detail.passPercentage}%`, 20, yPosition);
      yPosition += 10;
      doc.text(`Student Fail: ${detail.failPercentage}%`, 20, yPosition);

      yPosition += 15;
    };

    questionDetails.forEach((detail, index) => {
      addQuestionDetails(detail, index);
    });

    if (yPosition + 30 > pageHeight) {
      doc.addPage();
      yPosition = 20;
      addPageBorder();
    }

    doc.setFontSize(14);
    doc.text("Recommendation:", 20, yPosition);
    yPosition += 10;

    doc.setFontSize(12);
    doc.text(
      "The face emotion detection analysis indicates that the student exhibited a range of emotional states throughout the exam.",
      20,
      yPosition,
      { maxWidth: 150 }
    );

    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    doc.setTextColor(150, 150, 150);
    doc.text("Generated by FEDES System", 20, 280);

    addPageBorder();

    doc.save("report.pdf");
  };

  return (
    <button id="downloadButton" onClick={handleDownload} className="btn">
      <i className="bi bi-download" id="downloadIconReport"></i>
      &nbsp;Download
    </button>
  );
};

export default EmotionDownload;
