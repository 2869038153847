
const navList = [
  
    {
      _id: 1,
      name: "Buy Credits",
      icon: "bi bi-currency-dollar",
      href:"/payment"
    },

    {
      _id: 2,
      name: "Create Exam",
      icon: "bi bi-plus-lg",
      href:"/create-exam"
    },
    {
      _id: 3,
      name: "Dashboard",
      icon: "bi bi-house-door",
      href:"/dashboard"
    },
    {
      _id: 4,
      name: "Reports",
      icon: "bi bi-file-earmark-text",
      href:"/report"
    },
    {
      _id: 5,
      name: "Created Exams",
      icon: "bi bi-file-earmark-word",
      href:"/created-exam"
    },
    {
      _id: 6,
      name: "Add Pin Code",
      icon: "bi bi-file-earmark-font", 
      href:"/add-pincode"
    },
    {
      _id: 7,
      name: "Purchase History",
      icon: "bi bi-clock-history", 
      href:"/purchase-history"
    },
    {
      _id: 8,
      name: "Settings",
      icon: "bi bi-gear",
      href:'/settings'
    },
   
  ];
  
  export default navList;
  