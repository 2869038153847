import React, { useState, useEffect } from "react";
import axios from "axios";
import "./teacher.css";
import Header from "../Admin Dashboard/Header/Header";
import Swal from "sweetalert2";
import config from "../../config";
import { Link } from "react-router-dom";

export default function Teachers() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getData(token);
        setData(tempData);
        setFilteredData(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching teachers data:", error);
      }
    };
    fetchData();
  }, []);

  const getData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/teacher/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const teachers = res.data?.teachers || [];

      return teachers;
    } catch (error) {
      console.log("Error fetching Subject data:", error);
      throw error;
    }
  };

  const removeTeacher = async (_id, teacher) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated. Redirecting to login page.");
        return;
      }

      const isConfirmed = await Swal.fire({
        title: "Are you sure?",
        text: `You want to delete this ${teacher.Name}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed.isConfirmed) {
        const finalURL = `${config.apiUrl}/v1/web/api/teacher/${_id}`;
        const res = await axios.delete(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(res.data);
        Swal.fire(
          "Deleted!",
          `The ${teacher.Name} has been deleted.`,
          "success"
        ).then(() => {
          setData(data.filter((teacher) => teacher._id !== _id));
          setFilteredData(
            filteredData.filter((teacher) => teacher._id !== _id)
          );
        });
      }
    } catch (error) {
      console.log("Error deleting teacher:", error);
      Swal.fire("Error!", "Failed to delete the Teacher.", "error");
    }
  };

  const handleSearchChange = (e) => {
    const searchKey = e.target.value.toLowerCase();
    setSearchQuery(searchKey);
    setCurrentPage(1);

    if (searchKey === "") {
      setFilteredData(data);
    } else {
      const result = data.filter(
        (teacher) =>
          (teacher.Name && teacher.Name.toLowerCase().includes(searchKey)) ||
          (teacher.userName &&
            teacher.userName.toLowerCase().includes(searchKey)) ||
          (teacher.telephone &&
            teacher.telephone.toLowerCase().includes(searchKey))
      );
      setFilteredData(result);
    }
  };

  const handleToggleStatus = async (teacher) => {
    const confirmation = await Swal.fire({
      title: `Are you sure`,
      text: `Do you want to change the status of  ${teacher.Name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel!",
    });

    if (confirmation.isConfirmed) {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("User not authenticated.");
          return;
        }

        const updatedStatus =
          teacher.status === "active" ? "inactive" : "active";
        const finalURL = `${config.apiUrl}/v1/web/api/teacher/update/${teacher._id}`;
        const res = await axios.put(
          finalURL,
          { status: updatedStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const updatedTeacher = res.data.teacher;
        setData((prevData) =>
          prevData.map((t) => (t._id === teacher._id ? updatedTeacher : t))
        );
        setFilteredData((prevFilteredData) =>
          prevFilteredData.map((t) =>
            t._id === teacher._id ? updatedTeacher : t
          )
        );

        if (updatedTeacher.status === "inactive") {
          Swal.fire(
            "Status Updated",
            `The  ${teacher.Name} has been set to inactive.`,
            "success"
          );
        }
      } catch (error) {
        console.log("Error updating teacher status:", error);
        Swal.fire("Error!", "Failed to update the Teacher's status.", "error");
      }
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <Header />
      <div className="container" id="TeacherContainer">
        <div className="row" id="RowCol">
          <div className="col">
            <div className="col-lg-3 mt-2 mb-2" id="teacherSearch">
              <input
                className="form-control"
                type="search"
                placeholder="Search"
                name="searchQuery"
                id="search"
                style={{ marginTop: "20%" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <table className="table table-hover" id="table">
          <thead>
            <tr>
              <th scope="col">
                <i className="fas fa-list"></i>
              </th>
              <th scope="col" id="tableRow">
                Teacher name
              </th>
              <th scope="col" id="tableRow">
                Email
              </th>
              <th scope="col" id="tableRow">
                Phone
              </th>
              <th scope="col" id="tableRow">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((teacher, index) => (
              <tr key={teacher._id}>
                <th scope="row">{indexOfFirstItem + index + 1}</th>
                <td id="teacherBody">
                  <img
                    src="images/teacher.png"
                    alt="logo"
                    id="teacherProfile"
                  />
                  &nbsp;&nbsp;
                  <Link to={`/admin-create-exam-list?teacherId=${teacher._id}`}>
                    <span id="teacherBody">{teacher.Name}</span>
                  </Link>
                </td>
                <td id="teacherBody">{teacher.userName}</td>
                <td id="teacherBody">{teacher.telephone}</td>
                <td>
                  <a className="btn" id="btnDownload" href="#1">
                    <i className="bi bi-download" id="downloadIcon"></i>
                  </a>
                  &nbsp;
                  <a
                    className="btn"
                    id="btnDelete"
                    href="#1"
                    onClick={() => removeTeacher(teacher._id, teacher)}
                  >
                    <i className="bi bi-trash" id="deleteIcon"></i>
                  </a>
                  &nbsp;&nbsp;
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={teacher.status === "active"}
                      onChange={() => handleToggleStatus(teacher)}
                    />
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div id="paginationTeacher">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <a
                  className="page-link"
                  href="# "
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </a>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${
                    index + 1 === currentPage ? "active" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="# "
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </a>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <a
                  className="page-link"
                  href="# "
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
