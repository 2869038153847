import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import Header from "../Admin Dashboard/Header/Header";
import Swal from "sweetalert2";
import config from "../../config";

export default function EditSubject() {
  const { id } = useParams();
  const history = useHistory();
  const [subject, setSubject] = useState();

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${config.apiUrl}/v1/web/api/subject/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSubject(res.data.subject);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id, token]);

  function handleChange(evt) {
    const { name, value } = evt.target;
    setSubject({
      ...subject,
      [name]: value,
    });
  }

  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${config.apiUrl}/v1/web/api/subject/update/${id}`,
        subject,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Updated Successfully!",
      }).then(() => {
        history.push("/add-subject");
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (!subject) {
    return <>Loading the data</>;
  }

  return (
    <div>
      <Header />
      <div className="container" id="addNewContainer">
        <form
          class="row g-3"
          oValidate
          onSubmit={(e) => onSubmitForm(e)}
          id="formSubject"
        >
          <div class="col-auto">
            <label for="inputText2" class="visually-hidden">
              Subject
            </label>
            <input
              type="text"
              class="form-control"
              id="inputText2"
              placeholder="Enter Subject"
              name="subjectName"
              defaultValue={subject.subjectName}
              onChange={handleChange}
            />
          </div>
          <div class="col-auto">
            <button
              type="submit"
              value="Submit"
              class="btn  mb-3"
              id="submitBtn"
            >
              Update Subject
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
