import React, { useState, useEffect } from "react";
import Header from "../Dashboard/Header/Header"
import "./report.css"
import { Link } from 'react-router-dom'
import axios from "axios";
import config from "../../config";

export default function Report() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getData(token);
        setData(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching subject data:", error);
      }
    };
    fetchData();
  }, []);


  const getData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/exam/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

     
      const createExams = res.data?.createExams || [];
      const userId = localStorage.getItem("userId");

      return createExams.filter(exam => exam.addedBy === userId);

     
    } catch (error) {
      console.log("Error fetching Subject data:", error);
      throw error;
    }

    
  };

  

  return (
    <div>
      <Header />
      <div className='container' id="CreatedExamContainer">
      <div className="row">
         
            {data.map((createExam, index) => (
              
                <div className="col-md-3 mb-3" key={index}>
                  
              <div className='card' key={index} id="examCard" >
                
                <div className='card-body' id="reportBody1">
                <Link to ={`/report-generate?exam_id=${createExam._id}`}>
                <button id="button" >
                      <img src="/images/Group87593.png" alt="logo" id="reportLogo"/>&nbsp;&nbsp;
                      <h5 className='card-title' id="reportTitle">{createExam.examName}</h5>
                      </button>
                      </Link>
                </div>
              </div>
              </div>
            ))}
          
          </div>
         
        </div>
    </div>
  )
}
