import React from "react";
import "./Header.css";
import Logo from "./Logo";
import SideBar from "../Sidebar/SideBar";
import Nav from "./Nav/Nav";
import { useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();

  const pageNames = {
    "teachers":"Teachers",
    "students":"Students",
    "add-new": "Add New",
    "add-subject": "Add Subject",
    "edit-subject" : "Edit Subject",
    "add-grade": "Add Grade",
    "edit-grade": "Edit Grade",
    "add-duration": "Add Duration",
    "edit-duration": "Edit Duration",
    "admin" : "Payments",
    "add-banner" : "Banners",
    "admin-create-exam-list" : "Created Exams",
    "update-defaultPoint" : "Update Default Credit",
    "emotion-images" : "All Emotions"
  };


  const currentPagePath = location.pathname.split("/")[1];
  const currentPageName = pageNames[currentPagePath] || "Dashboard";

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <Logo />&nbsp;&nbsp;&nbsp;
      <h5>{currentPageName.charAt(0).toUpperCase() + currentPageName.slice(1)}</h5>
      <SideBar />
      <Nav />
    </header>
  );
}

export default Header;
