import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Dashboard/Header/Header";
import { useParams } from "react-router-dom";
import "./createdExam.css";
import config from "../../config";

export default function QuestionDetails() {
  const [question, setQuestion] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempQuestion = await getQuestionById(id, token);
        setQuestion(tempQuestion);
        console.log(JSON.stringify(tempQuestion));
      } catch (error) {
        console.log("Error fetching Question data:", error);
      }
    };
    fetchData();
  }, [id]);

  const getQuestionById = async (id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return null;
      }

      const response = await axios.get(
        `${config.apiUrl}/v1/web/api/question/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.question;
    } catch (error) {
      console.log("Error fetching Question data:", error);
      throw error;
    }
  };

  const renderQuestionTitle = (title) => {
    return { __html: title };
  };

  const {
    questionType,
    questionTitle,
    optionType,
    optionA,
    optionB,
    optionC,
    optionD,
    optionE,
    questionImage,
    answer,
    explanation,
  } = question;

  return (
    <div>
      <Header />
      <div className="container" id="detailsContiner">
        <h4 id="AllSupplier">{questionType}</h4>
        <hr />
        <dl className="row">
          <dt className="col-sm-3" id="supplier">
            Question Title
          </dt>
          <dd
            className="col-sm-9"
            id="details"
            dangerouslySetInnerHTML={renderQuestionTitle(questionTitle)}
          ></dd>

          <br />
          <br />
          <dt className="col-sm-3" id="supplier">
            Option Type
          </dt>
          <dd className="col-sm-9" id="details">
            {optionType}
          </dd>
          <br />
          <br />

          {optionType === "truefalse" && (
            <>
              <dt className="col-sm-3" id="supplier">
                Option A
              </dt>
              <dd
                className="col-sm-9"
                id="details"
                dangerouslySetInnerHTML={renderQuestionTitle(optionA)}
              ></dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option B
              </dt>
              <dd
                className="col-sm-9"
                id="details"
                dangerouslySetInnerHTML={renderQuestionTitle(optionB)}
              ></dd>
              <br />
              <br />
            </>
          )}

          {optionType === "image" && (
            <>
              {questionImage && (
                <>
                  <dt className="col-sm-3" id="supplier">
                    Question Image
                  </dt>
                  <dd className="col-sm-9" id="details">
                    {questionImage}
                  </dd>
                  <br />
                  <br />
                </>
              )}

              <dt className="col-sm-3" id="supplier">
                Option A
              </dt>
              <dd
                className="col-sm-9"
                id="details"
                dangerouslySetInnerHTML={renderQuestionTitle(optionA)}
              ></dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option B
              </dt>
              <dd
                className="col-sm-9"
                id="details"
                dangerouslySetInnerHTML={renderQuestionTitle(optionB)}
              ></dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option C
              </dt>
              <dd
                className="col-sm-9"
                id="details"
                dangerouslySetInnerHTML={renderQuestionTitle(optionC)}
              ></dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option D
              </dt>
              <dd
                className="col-sm-9"
                id="details"
                dangerouslySetInnerHTML={renderQuestionTitle(optionD)}
              ></dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option E
              </dt>
              <dd
                className="col-sm-9"
                id="details"
                dangerouslySetInnerHTML={renderQuestionTitle(optionE)}
              ></dd>
              <br />
              <br />
            </>
          )}

          {optionType === "imageanswer" && (
            <>
              <dt className="col-sm-3" id="supplier">
                Question Image
              </dt>
              <dd className="col-sm-9" id="details">
                {questionImage}
              </dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option A
              </dt>
              <dd className="col-sm-9" id="details">
                {optionA}
              </dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option B
              </dt>
              <dd className="col-sm-9" id="details">
                {optionB}
              </dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option C
              </dt>
              <dd className="col-sm-9" id="details">
                {optionC}
              </dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option D
              </dt>
              <dd className="col-sm-9" id="details">
                {optionD}
              </dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option E
              </dt>
              <dd className="col-sm-9" id="details">
                {optionE}
              </dd>
              <br />
              <br />
            </>
          )}

          {optionType === "textonly" && (
            <>
              <dt className="col-sm-3" id="supplier">
                Option A
              </dt>
              <dd
                className="col-sm-9"
                id="details"
                dangerouslySetInnerHTML={renderQuestionTitle(optionA)}
              ></dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option B
              </dt>
              <dd
                className="col-sm-9"
                id="details"
                dangerouslySetInnerHTML={renderQuestionTitle(optionB)}
              ></dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option C
              </dt>
              <dd
                className="col-sm-9"
                id="details"
                dangerouslySetInnerHTML={renderQuestionTitle(optionC)}
              ></dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option D
              </dt>
              <dd
                className="col-sm-9"
                id="details"
                dangerouslySetInnerHTML={renderQuestionTitle(optionC)}
              ></dd>
              <br />
              <br />
              <dt className="col-sm-3" id="supplier">
                Option E
              </dt>
              <dd
                className="col-sm-9"
                id="details"
                dangerouslySetInnerHTML={renderQuestionTitle(optionD)}
              ></dd>
              <br />
              <br />
            </>
          )}
          <dt className="col-sm-3" id="supplier">
            Correct Index
          </dt>
          <dd className="col-sm-9" id="details">
            {answer}
          </dd>
          <br />
          <br />
          <dt className="col-sm-3" id="supplier">
            Additional Note
          </dt>
          <dd
            className="col-sm-9"
            id="details"
            dangerouslySetInnerHTML={renderQuestionTitle(explanation)}
          ></dd>
        </dl>
        <div id="backBtnDiv">
          <div className="col" id="cancel">
            <button
            style={{borderRadius:"8px"}}
              className="btn"
              id="AddQuestionBtnBack"
              onClick={() => window.history.back()}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
