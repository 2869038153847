import React, { Component } from 'react'
import Header from "../../component/Dashboard/Header/Header"
import "./physics.css"

export default class Physics extends Component {
  render() {
    return (
      <div>
        <Header/>
        <div >

          {/*Card */}
          <div id="examContainer">
          
          <div className='row' id="examRow1" >
           
           <div className='col-md-2' id="examCol">
             <div className='card' id="examCard1" >
               <div className='card-body' id="examBody">
                 <h5 className='card-title' id="examTitle">STUDENTS FACED EXAM</h5>
                 <span id="examNumber">2,386</span>
               </div>
             </div>
           </div>
   
           <div className='col-md-2' id="examCol">
             <div className='card' id="examCard1" >
               <div className='card-body' id="examBody">
                 <h5 className='card-title' id="examTitle">COMPLETED ONTIME</h5>
                 <span id="examNumber">2,386</span>
               </div>
             </div>
           </div>


           <div className='col-md-2' id="examCol">
             <div className='card' id="examCard1" >
               <div className='card-body' id="examBody">
                 <h5 className='card-title' id="examTitle">FAILDED TO COMPLETE</h5>
                 <span id="examNumber">2,386</span>
               </div>
             </div>
           </div>
   
           <div className='col-md-2' id="examCol">
             <div className='card' id="examCard1" >
               <div className='card-body' id="examBody">
                 <h5 className='card-title' id="examTitle">AVERAGE RESULTS</h5>
                 <span id="examNumber">2,386</span>
               </div>
             </div>
           </div>

           <div className='col-md-2' id="examColBtn">
            <div className='row' id="dowloadRow">
              <button className='btn' id="downloadAll"><i class="bi bi-download" id='dowloadIcon'></i>&nbsp;Download All</button>
            </div>
            <div className='row'  id="deleteRow">
            <button className='btn' id="deleteAll"><i className='bi bi-trash' id='deleteIcon'></i>&nbsp;Delete All</button>
            </div>
           </div>
         </div>


          </div>
          {/*Card */}

      <div className='container' id="ReportContainer">
        <div className='row' id="RowCol">
            <div className='col'>
         <div className='col-lg-3 mt-2 mb-2' id="search">
            <input  className="form-control"
            type='search'
            placeholder='Search'
            name="serchQuery"
            id="search"/>
         </div>
         </div>

         <div className='col' id="btnCol1">
            <button id="filter1" className='btn'><img src="images/filter.png" alt="logo"/>&nbsp;Filter Report</button>
         </div>
         </div>

         <table className='table table-hover' id="table">
            <thead>
                <tr>
                    <th scope='col' id="tableRow"><input type="checkbox" id="checkbox" name="checkbox"/>&nbsp;Student name</th>
                    <th scope='col' id="tableRow">Results</th>
                    <th scope='col' id="tableRow">Time</th>
                    <th scope='col' id="tableRow">Date</th>
                    <th scope='col' id="tableRow">Status</th>
                    <th scope='col' id="tableRow">Action</th>
                </tr>
            </thead>
        <tbody id="tbody">
                <tr>
                    <td><input type="checkbox" id="checkbox" name="checkbox"/>&nbsp;<img src="images/Ellipse238.png" alt="logo" id="teacherProfile"/>&nbsp;<span id="teacherName">Ashlynn Siphron</span></td>
                    <td>81%</td>
                    <td>8:00 AM</td> 
                    <td>17.01.2024</td> 
                    <td><span id="completed">Completed</span></td>
                    <td>
                        <a type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" id="btnDownload" href='#1' style={{border:"none"}}>
                        <i class="bi bi-download" id='dowloadIcon'></i>
                        </a>
                        &nbsp;
                        <a className='btn' id="btnDelete" href='#1'>
                            <i className='bi bi-trash' id='deleteIcon'></i>
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href='# ' id="view">VIEW</a>
                    </td>
                   
                </tr>
        </tbody>
         </table>  
      </div>
      

{/*<!-- Modal -->*/}
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><button id="downloadButton"><i class="bi bi-download" id='dowloadIconReport'></i>&nbsp;Download</button></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <div id="reportLogoDiv">
      <img src="/images/report.png" alt="logo" id="reportLogo2"/>
      </div>

      <div id="reportTextDiv">
      <span id="reportText">The face emotional detection results report from FEDESTM</span>
      </div>
{/*table */}
      <div id="tableReport">
      <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry the Bird</td>
      <td>Thornton</td>
      <td>@twitter</td>
    </tr>
  </tbody>
</table>
      </div>
      <div id="reportTitleSpanDiv">
        <span id="reportTitleSpan">Student’s emotion during the exam</span>
        </div>

        <div id="AllCard">
        <div className='row'>
          <div className='col' id="card">
          <div class="card" >
  <div class="card-body">
    <h5 class="card-title" id="cardTitleReport">Student Engagement</h5>
    <p class="card-text" id="cardTextReport" >Remained consistent throughout the exam</p>
    <span id="StatusGood">Good!</span>
  </div>
</div>
          </div>

          <div className='col'>
          <div class="card" id="card" >
  <div class="card-body">
    <h5 class="card-title" id="cardTitleReport">Emotional State</h5>
    <p class="card-text" id="cardTextReport">Remained consistent throughout the exam</p>
    <span id="StatusBad">Bad!</span>
  </div>
</div>
          </div>
        </div>

        <div className='row' id="secondRow">
          <div className='col'>
          <div class="card" id="card" >
  <div class="card-body">
    <h5 class="card-title" id="cardTitleReport">Intensity of Emotional</h5>
    <p class="card-text" id="cardTextReport">Remained consistent throughout the exam</p>
    <span id="StatusNormal">Normal!</span>
  </div>
</div>
          </div>

          <div className='col'>
          <div class="card" id="card" >
  <div class="card-body">
    <h5 class="card-title" id="cardTitleReport">Transitions</h5>
    <p class="card-text" id="cardTextReport">Remained consistent throughout the exam</p>
    <span id="StatusBad">Bad!</span>
  </div>
</div>
          </div>
        </div>
        </div>
      </div>
      <div class="modal-footer">
        <span id="recommendation">Recommendation:</span>
        <span id="footerDetails">The face emotion detection analysis indicates that the student remained actively engaged and exhibited a range of emotional states throughout the exam.</span>
      </div>
    </div>
  </div>
</div>
      </div>
      </div>
    )
  }
}
