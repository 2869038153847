import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Admin Dashboard/Header/Header";
import config from "../../config";
import "./updatePoint.css";

export default function DefaultList() {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentSetting, setCurrentSetting] = useState(null);
  const [defaultPoint, setDefaultPoint] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        alert("User not authenticated.");
        return;
      }

      try {
        const tempData = await getData(token);
        setData(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching setting data:", error);
      }
    };
    fetchData();
  }, []);

  const getData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/setting/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const setting = res.data?.settings || [];

      return setting;
    } catch (error) {
      console.log("Error fetching duration data:", error);
      throw error;
    }
  };

  const handleEditClick = (setting) => {
    setCurrentSetting(setting);
    setDefaultPoint(setting.defaultPoint);
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        alert("User not authenticated.");
        return;
      }

      const finalURL = `${config.apiUrl}/v1/web/api/setting/${currentSetting._id}`;
      await axios.put(
        finalURL,
        { defaultPoint },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedData = await getData(token);
      setData(updatedData);
      setShowModal(false);
    } catch (error) {
      console.log("Error updating setting data:", error);
    }
  };

  return (
    <div>
      <Header />
      <div className="container" id="PointContainer">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">
                <i className="fas fa-list"></i>
              </th>
              <th scope="col">Default Credit</th>
              <th scope="col">Action</th>
            </tr>
          </thead>

          <tbody>
            {data.map((setting, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td id="supplier">{setting.defaultPoint}</td>
                <td>
                  <button
                    className="btn"
                    id="editBtn"
                    onClick={() => handleEditClick(setting)}
                    data-toggle="modal"
                    data-target="#editModal"
                  >
                    <i className="fas fa-edit" id="editIcon"></i>
                  </button>
                  &nbsp;
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="editModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editModalLabel">
                Edit Default Point
              </h5>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="formDefaultPoint">Default Point</label>
                  <input
                    type="text"
                    className="form-control"
                    id="formDefaultPoint"
                    value={defaultPoint}
                    onChange={(e) => setDefaultPoint(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              <button
                type="button"
                id="submitBtn"
                className="btn"
                onClick={handleSaveChanges}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
