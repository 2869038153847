import React, { useState, useEffect } from "react";
import axios from "axios";

import Swal from "sweetalert2";
import config from "../../config";
import "./banner.css";

export default function BannerList({ imageName }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getData(token);
        setData(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching grade data:", error);
      }
    };
    fetchData();
  }, []);

  const getData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/banner/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const banners = res.data?.banners || [];

      return banners;
    } catch (error) {
      console.log("Error fetching banner data:", error);
      throw error;
    }
  };

  const removeBanner = async (_id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated. Redirecting to login page.");
        return;
      }

      const isConfirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this banner?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed.isConfirmed) {
        const finalURL = `${config.apiUrl}/v1/web/api/banner/${_id}`;
        const res = await axios.delete(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(res.data);
        Swal.fire("Deleted!", "The banner has been deleted.", "success").then(
          () => {
            setData(data.filter((banner) => banner._id !== _id));
          }
        );
      }
    } catch (error) {
      console.log("Error deleting grade:", error);
      Swal.fire("Error!", "Failed to delete the banner.", "error");
    }
  };

  return (
    <div id="bannerTable">
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">
              <i className="fas fa-list"></i>
            </th>
            <th scope="col">Banner Image</th>
            <th scope="col">Image Name</th>
            <th scope="col">Expire Date</th>
            <th scope="col">Action</th>
          </tr>
        </thead>

        <tbody>
          {data.map((banner, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>

              <td id="supplier">
                <img
                  src={`${config.apiUrl}/uploads/banners/${banner.image}`}
                  alt={`Banner ${index + 1}`}
                  style={{
                    maxWidth: "40px",
                    maxHeight: "30px",
                    cursor: "pointer",
                    borderRadius: "100%",
                  }}
                  onClick={() =>
                    window.open(
                      `${config.apiUrl}/uploads/banners/${banner.image}`,
                      "_blank"
                    )
                  }
                />
              </td>

              <td id="supplier">{banner.image}</td>
              <td id="supplier">
                {new Date(banner.expire_date).toLocaleDateString()}
              </td>
              <td>
                <button
                  className="btn"
                  id="editDelete"
                  onClick={() => removeBanner(banner._id)}
                >
                  <i className="fas fa-trash-alt" id="DeleteIcon"></i>&nbsp;
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
