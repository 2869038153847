/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Header from "../Dashboard/Header/Header";
import "./index.css";
import axios from "axios";
import config from "../../config";

export default function Index() {
  const [exam, setExam] = useState([]);
  const [questionCounts, setQuestionCounts] = useState({});

  useEffect(() => {
    const fetchExamData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getExamData(token);
        setExam(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching subject data:", error);
      }
    };
    fetchExamData();
  }, []);

  const getExamData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/exam/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const createExams = res.data?.createExams || [];
      const userId = localStorage.getItem("userId");

      return createExams.filter((exam) => exam.addedBy === userId);
    } catch (error) {
      console.log("Error fetching Subject data:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (exam.length > 0) {
      const fetchQuestionCounts = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("User not authenticated.");
          return;
        }

        try {
          const questionCounts = await getQuestionCounts(token);
          setQuestionCounts(questionCounts);
          console.log(JSON.stringify(questionCounts));
        } catch (error) {
          console.log("Error fetching question counts:", error);
        }
      };
      fetchQuestionCounts();
    }
  }, [exam]);

  const getQuestionCounts = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/question/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const questions = res.data?.questions || [];
      const counts = {};

      exam.forEach((exam) => {
        const examQuestions = questions.filter(
          (question) => question.exam_id === exam._id
        );
        counts[exam._id] = examQuestions.length;
      });

      return counts;
    } catch (error) {
      console.log("Error fetching question data:", error);
      throw error;
    }
  };

  const numberOfExams = exam.length;
  const totalNumberOfQuestions = Object.values(questionCounts).reduce(
    (total, count) => total + count,
    0
  );

  return (
    <div>
      <Header />
      <div className="container" id="cardContainer">
        <h3 id="statictise">Statistics</h3>
        <div className="row" id="row">
          <div className="col-md-2" id="card">
            <div className="card" id="card1" style={{ width: "16rem" }}>
              <div className="card-body">
                <h5 className="card-title" id="cardTtile">
                  NO. OF QUESTIONS
                </h5>
                <p className="card-text" id="cardText">
                  {totalNumberOfQuestions}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-2" id="card">
            <div className="card" id="card1" style={{ width: "16rem" }}>
              <div className="card-body">
                <h5 className="card-title" id="cardTtile">
                  TOTAL REPORTS
                </h5>
                <p className="card-text" id="cardText">
                  {numberOfExams}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-2" id="card">
            <div className="card" id="card1" style={{ width: "16rem" }}>
              <div className="card-body">
                <h5 className="card-title" id="cardTtile">
                  NO. OF EXAMS
                </h5>
                <p className="card-text" id="cardText">
                  {numberOfExams}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
