import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config";
import { Link } from "react-router-dom";

export default function CreatedExamList() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getData(token);
        const updatedData = await getExamDetails(tempData, token);
        setData(updatedData);
        console.log(JSON.stringify(updatedData));
      } catch (error) {
        console.log("Error fetching exam data:", error);
      }
    };

    fetchData();
  }, []);

  const getData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/create-exam`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const createExams = res.data.createExams || [];
      const userId = localStorage.getItem("userId");

      return createExams.filter((exam) => exam.addedby === userId & exam.visible === "true");
    } catch (error) {
      console.log("Error fetching create exams data:", error);
      throw error;
    }
  };

  const getExamDetails = async (createExams, token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/exam`;
      const examDetailsPromises = createExams.map(async (createExam) => {
        try {
          const res = await axios.get(`${finalURL}/${createExam.examName}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const { examName } = res.data.createExam;

          return {
            ...createExam,
            examName: examName || "Unknown Exam Name",
          };
        } catch (error) {
          console.log(
            `Error fetching details for exam ${createExam.examName}:`,
            error
          );
          return { ...createExam, examName: "Unknown Exam Name" };
        }
      });

      const updatedExams = await Promise.all(examDetailsPromises);
      return updatedExams;
    } catch (error) {
      console.log("Error fetching exam details:", error);
      throw error;
    }
  };

  const removeExam = async (_id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated. Redirecting to login page.");
        return;
      }

      const isConfirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this exam?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed.isConfirmed) {
        const finalURL = `${config.apiUrl}/v1/web/api/create-exam/${_id}`;
        const res = await axios.delete(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(res.data);
        Swal.fire("Deleted!", "The Exam has been deleted.", "success").then(
          () => {
            setData(data.filter((exam) => exam._id !== _id));
          }
        );
      }
    } catch (error) {
      console.log("Error deleting Exam:", error);
      Swal.fire("Error!", "Failed to delete the exam.", "error");
    }
  };

  return (
    <div id="modifyPinCode" >
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">
              <i className="fas fa-list"></i>
            </th>
            <th scope="col" id="headName">Exam Names</th>
            <th scope="col" id="headName">Pin Codes</th>
            <th scope="col" id="headName">Expire Dates</th>
            <th scope="col" id="headName">Notes</th>
            <th scope="col" id="headName">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((createExams, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td id="supplier">{createExams.examName}</td>
              <td id="supplier">{createExams.pinCode}</td>

              <td id="supplier">
                {new Date(createExams.expire_date).toLocaleDateString()}
              </td>
              <td id="supplier">{createExams.note}</td>
              <td>
                <Link
                  className="btn"
                  id="editBtn"
                  to={`/edit-createExam/${createExams._id}`}
                >
                  <i className="fas fa-edit" id="editIcon"></i>
                </Link>
                &nbsp;
                <button
                  className="btn"
                  id="editDelete"
                  onClick={() => removeExam(createExams._id)}
                >
                  <i className="fas fa-trash-alt" id="DeleteIcon"></i>&nbsp;
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
