import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config";

export default function Question({ exam_id }) {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("User not authenticated.");
      return;
    }

    try {
      const tempData = await getData(token);
      setData(tempData);
      console.log(JSON.stringify(tempData));
    } catch (error) {
      console.log("Error fetching Question data:", error);
    }
  };

  const getData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/question/examId/${exam_id}`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const questions = res.data?.data || [];

      return questions;
    } catch (error) {
      console.log("Error fetching Question data:", error);
      throw error;
    }
  };

  const removeQuestion = async (questionId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated. Redirecting to login page.");
        return;
      }

      const isConfirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this Question?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed.isConfirmed) {
        const finalURL = `${config.apiUrl}/v1/web/api/question/${questionId}`;
        await axios.delete(finalURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const updatedQuestions = data.filter(
          (question) => question._id !== questionId
        );
        setData(updatedQuestions);

        Swal.fire("Deleted!", "The Question has been deleted.", "success").then(
          () => {}
        );
      }
    } catch (error) {
      console.log("Error deleting Question:", error);
      Swal.fire("Error!", "Failed to delete the Question.", "error");
    }
  };

  const StatusColor = (status) => {
    return status === "active" ? "#28a745" : "tomato";
  };

  const renderQuestionTitle = (title) => {
    const maxLength = 20;

    if (title.length > maxLength) {
      return { __html: title.substring(0, maxLength) + "..." };
    } else {
      return { __html: title };
    }
  };

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exam_id]);

  return (
    <div className="container">
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">
              <i className="fas fa-list"></i>
            </th>
            <th scope="col">Question Type</th>
            <th scope="col">Question Title</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>

        <tbody>
          {data.map((question, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{question.questionType}</td>
              <td
                dangerouslySetInnerHTML={renderQuestionTitle(
                  question.questionTitle
                )}
              ></td>
              <td style={{ color: StatusColor(question.status) }}>
                {question.status}
              </td>
              <td>
                <a
                  className="btn"
                  id="editBtn"
                  href={`/edit/question/${question._id}`}
                >
                  <i className="fas fa-edit" id="editIcon"></i>
                </a>
                &nbsp;
                <button
                  className="btn"
                  id="editDelete"
                  onClick={() => removeQuestion(question._id)}
                >
                  <i className="fas fa-trash-alt" id="DeleteIcon"></i>&nbsp;
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href={`/question/${question._id}`} id="view">
                  VIEW
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
