import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Admin Dashboard/Header/Header";
import config from "../../config";
import "./emotion.css";

export default function GetEmotionImages() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        alert("User not authenticated.");
        return;
      }

      try {
        const tempData = await getData(token);
        setData(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching duration data:", error);
      }
    };
    fetchData();
  }, []);

  const getData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/image/`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const emotion = res.data?.imotions || [];

      return emotion;
    } catch (error) {
      console.log("Error fetching duration data:", error);
      throw error;
    }
  };

  const StatusColor = (status) => {
    let color;
    if (status === "happy") {
      color = "#4DB6AC";
    } else if (status === "sad") {
      color = "#F65656";
    } else if (status === "surprise") {
      color = "#9747FF";
    } else if (status === "neutral") {
      color = "#FFA216";
    } else {
      color = "tomato";
    }
    return color;
  };

  const StatusCodeColor = (status_code) => {
    let color;
    if (status_code === "201") {
      color = "#28a745";
    } else {
      color = "tomato";
    }
    return color;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = data.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div>
      <Header />
      <div className="container" id="emotionContainer">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">
                <i className="fas fa-list"></i>
              </th>
              <th scope="col">Images</th>
              <th scope="col">Status Code</th>
              <th scope="col">Status</th>
            </tr>
          </thead>

          <tbody>
            {currentData.map((images, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td id="supplier">
                  <img
                    src={`${config.apiUrl}/v1/mobile/api/uploads/${images.exam_id}/${images.image}`}
                    alt={`Banner ${index + 1}`}
                    style={{
                      maxWidth: "40px",
                      maxHeight: "30px",
                      cursor: "pointer",
                      borderRadius: "100%",
                    }}
                    onClick={() =>
                      window.open(
                        `${config.apiUrl}/v1/mobile/api/uploads/${images.exam_id}/${images.image}`,
                        "_blank"
                      )
                    }
                  />
                </td>
                <td
                  id="supplier"
                  style={{ color: StatusCodeColor(images.status_code) }}
                >
                  {images.status_code}
                </td>
                <td id="supplier" style={{ color: StatusColor(images.status) }}>
                  {images.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div id="Emotionpagination">
          <nav>
            <ul className="pagination">
              {[...Array(totalPages)].map((_, page) => (
                <li
                  key={page}
                  className={`page-item ${
                    page + 1 === currentPage ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(page + 1)}
                  >
                    {page + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
