import React, { useState } from "react";
import axios from "axios";
import Header from "../Admin Dashboard/Header/Header";
import Swal from "sweetalert2";
import GradeList from "./GradeList";
import config from "../../config";

export default function AddGrade() {
  const [data, setData] = useState({
    gradeName: "",
  });

  function handleChange(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    setData({
      ...data,
      [name]: value,
    });
  }

  const onSubmitForm = async (e) => {
    try {
      e.preventDefault();

      const token = localStorage.getItem("token");

      const res = await axios({
        method: "post",
        baseURL: config.apiUrl,
        url: "/v1/web/api/grade/create",
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(res.data);

      // Display success message using SweetAlert
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data Saved Successfully!",
      }).then(() => {
        window.location.assign("/add-grade");
      });
    } catch (error) {
      console.log(error);

      // Display error message using SweetAlert
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while saving the data. Please try again.",
      });
    }
  };

  return (
    <div>
      <Header />
      <div className="container" id="addNewContainer">
        <form
          class="row g-3"
          oValidate
          onSubmit={(e) => onSubmitForm(e)}
          id="formSubject"
        >
          <div class="col-auto">
            <label for="inputText2" class="visually-hidden">
              Grade
            </label>
            <input
              type="text"
              class="form-control"
              id="inputText2"
              placeholder="Enter Grade"
              name="gradeName"
              value={data.gradeName}
              onChange={handleChange}
            />
          </div>
          <div class="col-auto">
            <button
              type="submit"
              value="Submit"
              class="btn  mb-3"
              id="submitBtn"
            >
              Add Grade
            </button>
          </div>
        </form>
        <GradeList />
      </div>
    </div>
  );
}
