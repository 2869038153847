import React, { useState } from "react";
import "./teacherReg.css";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config";
import { Link } from "react-router-dom";

export default function TeacherRegister() {
 
  const [data, setData] = useState({
    Name: "",
    userName: "",
    password: "",
    re_password: "",
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validatePasswordStrength = () => {
    const { password } = data;
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (
      password.length < minLength ||
      !hasUpperCase ||
      !hasLowerCase ||
      !hasNumber ||
      !hasSpecialChar
    ) {
      Swal.fire({
        icon: "error",
        title: "Weak Password",
        text: `Password must be at least ${minLength} characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.`,
      });
      return false;
    }
    return true;
  };

  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(data.userName)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email Address",
        text: "Email address must be in the format 'user@gmail.com'.",
      });
      return false;
    }
    return true;
  };

  function handleChange(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    setData({
      ...data,
      [name]: value,
    });
  }

  const validatePasswords = () => {
    if (data.password !== data.re_password) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Passwords do not match.",
      });
      return false;
    }
    return true;
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (
      !validatePasswords() ||
      !validatePasswordStrength() ||
      !validateEmail()
    ) {
      return;
    }

    try {
      const res = await axios({
        method: "post",
        baseURL: config.apiUrl,
        url: "/v1/web/api/user/teacher/signup",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(res.data);
      Swal.fire({
        icon: "success",
        title: "Signup Successful!",
        text: "You have successfully Registered.",
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem("userId", res.data.user._id);
          window.location.assign("/dashboard");
        }
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while signing up. Please try again.",
      });
    }
  };

  return (
    <div id="logindev">
      <section className="text-center text-lg-start">
        <div className="card mb-3">
          <div className="row g-0 d-flex align-items-center">
            <div className="col-md-8">
              <img
                src="/images/regBg.png"
                alt="login background"
                className="background-image"
              />
              <div className="overlayText">
                <h1>Recognize student's facial expressions during the exams</h1>
                <p>
                  Facial Expression Recognition System (FERS) tailored to
                  recognize students' facial expressions
                </p>
              </div>
            </div>

            <div className="col" id="regDiv">
              <div id="imageDiv">
                <div id="fedesLogo">
                  <img src="/images/face-detection.png" alt="logo" />
                </div>
                <div id="faceCapture">
                  <span id="faceName">Face Capture</span>
                </div>
              </div>

              <div id="registerHeaderDiv">
                <span id="registerHeader">Sign Up for Face Capture (1/3)</span>
              </div>

              <div id="subHeaderDev">
                <span id="subHeader">
                  Please enter your personal information
                </span>
              </div>

              <div id="formDiv1">
                <div className="card-body py-4 px-md-5">
                  <form onSubmit={(e) => onSubmitForm(e)}>
                    <div className="form-outline mb-2">
                      <label
                        className="form-label"
                        htmlFor="form2Example1"
                        id="lableForm"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="form2Example1"
                        className="form-control"
                        name="Name"
                        value={data.Name}
                        onChange={handleChange}
                        placeholder="Enter your name"
                      />
                    </div>

                    <div className="form-outline mb-4">
                      <label
                        className="form-label"
                        htmlFor="form2Example2"
                        id="lableForm"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        id="form2Example2"
                        className="form-control"
                        name="userName"
                        value={data.userName}
                        onChange={handleChange}
                        placeholder="Enter your email address"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        title="Please enter a valid email address (e.g., user@example.com)"
                      />
                    </div>

                    <div className="form-outline mb-4">
                      <label
                        className="form-label"
                        htmlFor="form2Example3"
                        id="lableForm"
                      >
                        Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          id="form2Example2"
                          className="form-control"
                          name="password"
                          value={data.password}
                          onChange={handleChange}
                          placeholder="Enter your password"
                          style={{ paddingRight: "50px" }}
                        />
                        <button
                          type="button"
                          id="visible"
                          className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
                          onClick={togglePasswordVisibility}
                          style={{ right: "10px" }}
                        >
                          {passwordVisible ? (
                            <i className="bi bi-eye-slash"></i>
                          ) : (
                            <i className="bi bi-eye"></i>
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="form-outline mb-4">
                      <label
                        className="form-label"
                        htmlFor="form2Example4"
                        id="lableForm"
                      >
                        Confirm Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          id="form2Example2"
                          className="form-control"
                          name="re_password"
                          value={data.re_password}
                          onChange={handleChange}
                          placeholder="Enter your password"
                          style={{ paddingRight: "50px" }}
                        />
                        <button
                          type="button"
                          id="visible"
                          className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
                          onClick={togglePasswordVisibility}
                          style={{ right: "10px" }}
                        >
                          {passwordVisible ? (
                            <i className="bi bi-eye-slash"></i>
                          ) : (
                            <i className="bi bi-eye"></i>
                          )}
                        </button>
                      </div>
                    </div>

                    <button
                      type="submit"
                      value="Login"
                      className="btn btn-block mb-2"
                      id="register"
                    >
                      Sign Up
                    </button>

                    <div className="row mb-4">
                      <div className="col">
                        <span id="signin">Do you have an account?</span>
                        <Link to={config.login} id="signup">
                          Sign In
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
