import { useEffect, useState } from "react";
import Header from "../Dashboard/Header/Header";
import "./AddQuestion.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import config from "../../config";
import Question from "./Question";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

export default function AddQuestions() {
  const [data, setData] = useState({
    questionType: "",
    questionTitle: "",
    optionType: "textonly",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    optionE: "",
    questionImage: "",
    answer: "",
    explanation: "",
    exam_id: "",
  });

  const location = useLocation();
  const [refreshQuestions, setRefreshQuestions] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const examIdParam = urlParams.get("examId");
    if (examIdParam) {
      setData((prevState) => ({ ...prevState, exam_id: examIdParam }));
    }
  }, [location.search]);

  function handleChange(evt) {
    const { name, value, files } = evt.target;

    const newValue = files ? files[0] : value;

    setData({
      ...data,
      [name]: newValue,
    });
  }

  const token = localStorage.getItem("token");

  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }

      const res = await axios({
        method: "post",
        baseURL: config.apiUrl,
        url: "/v1/web/api/question/create",
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(res.data);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data Saved Successfully!",
      }).then((result) => {
        setRefreshQuestions(prev => !prev);
        window.location.reload(false)
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while saving the data.",
      });
    }
  };

  const [examName, setExamName] = useState("");
  const [subject, setSubject] = useState("");
  const [grade, setGrade] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const examNameParam = urlParams.get("examName");
    const subjectParam = urlParams.get("subject");
    const gradeParam = urlParams.get("grade");
    const examIdParam = urlParams.get("examId");

    setExamName(examNameParam);
    setSubject(subjectParam);
    setGrade(gradeParam);
    setData((prevState) => ({ ...prevState, exam_id: examIdParam }));
  }, []);

  return (
    <div>
      <div>
        <Header />
        <div className="container" id="AddQuestionContainer">
          <div className="row" id="AddQuestionRow1">
            <div className="col-md-2" id="AddQuestionCard">
              <div className="card" id="AddQuestionCard1">
                <div className="card-body">
                  <h5 className="card-title" id="AddQuestionCardTtile">
                    EXAM NAME
                  </h5>
                  <p className="card-text" id="AddQuestionCardText">
                    {examName}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-2" id="AddQuestionCard">
              <div className="card" id="AddQuestionCard2">
                <div className="card-body">
                  <h5 className="card-title" id="AddQuestionCardTtile">
                    SUBJECT
                  </h5>
                  <p className="card-text" id="AddQuestionCardText">
                    {subject}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-2" id="AddQuestionCard">
              <div className="card" id="AddQuestionCard3">
                <div className="card-body">
                  <h5 className="card-title" id="AddQuestionCardTtile">
                    GRADE
                  </h5>
                  <p className="card-text" id="AddQuestionCardText">
                    {grade}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={(e) => onSubmitForm(e)} id="QuestionForm">
            <div className="row" id="AddQuestionRow2">
              <div className="col-md-4" id="AddQuestionCol">
                <label htmlFor="form_name" id="AddQuestionHeader">
                  <b>Question Type</b>
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  id="form_name"
                  value={data.questionType}
                  onChange={handleChange}
                  name="questionType"
                >
                  <option selected>Select question type</option>
                  <option value="Past Paper" name="Past Paper">
                    Past Paper
                  </option>
                  <option value="Model Paper" name="Model Paper">
                    Model Paper
                  </option>
                </select>
              </div>

              <div className="col-md-7" id="AddQuestionCol">
                <label htmlFor="form_name" id="AddQuestionHeader">
                  <b>Option Type</b>
                </label>
                <div className="row">
                  <div className="col-md-2">
                    <input
                      type="radio"
                      id="option1"
                      name="optionType"
                      value="truefalse"
                      checked={data.optionType === "truefalse"}
                      onChange={handleChange}
                      className="custom-radio"
                    />
                    <label htmlFor="option1" className="custom-radio-label">
                      True/False
                    </label>
                  </div>

                  <div className="col-md-2">
                    <input
                      type="radio"
                      id="option2"
                      name="optionType"
                      value="image"
                      checked={data.optionType === "image"}
                      onChange={handleChange}
                      className="custom-radio"
                    />
                    <label htmlFor="option2" className="custom-radio-label">
                      Images
                    </label>
                  </div>

                  <div className="col-md-3">
                    <input
                      type="radio"
                      id="option3"
                      name="optionType"
                      value="imageanswer"
                      checked={data.optionType === "imageanswer"}
                      onChange={handleChange}
                      className="custom-radio"
                    />
                    <label htmlFor="option3" className="custom-radio-label">
                      Images Answer
                    </label>
                  </div>

                  <div className="col">
                    <input
                      type="radio"
                      id="option4"
                      name="optionType"
                      value="textonly"
                      checked={data.optionType === "textonly"}
                      onChange={handleChange}
                      className="custom-radio"
                    />
                    <label htmlFor="option4" className="custom-radio-label">
                      Text Only
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" id="AddQuestionRow4">
              <div className="col-md-10" id="textEditor">
                <label htmlFor="form_name" id="AddQuestionHeader">
                  <b>Question title</b>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={data.questionTitle}
                  onChange={(event, editor) => {
                    const updatedContent = editor.getData();
                    setData({ ...data, questionTitle: updatedContent });
                  }}
                />
              </div>
            </div>

            {data.optionType === "truefalse" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-5" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option A</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={data.optionA}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setData({ ...data, optionA: updatedContent });
                    }}
                  />
                </div>

                <div className="col-md-5" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option B</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={data.optionB}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setData({ ...data, optionB: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {data.optionType === "image" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-5" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Question Image </b>
                    <span id="optional">(optional)</span>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="questionImage"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            {data.optionType === "image" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-5" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option A</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={data.optionA}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setData({ ...data, optionA: updatedContent });
                    }}
                  />
                </div>

                <div className="col-md-5" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option B</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={data.optionB}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setData({ ...data, optionB: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {data.optionType === "image" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-5" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option C</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={data.optionC}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setData({ ...data, optionC: updatedContent });
                    }}
                  />
                </div>

                <div className="col-md-5" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option D</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={data.optionD}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setData({ ...data, optionD: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {data.optionType === "image" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-5" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option E </b> <span id="optional">(optional) </span>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={data.optionE}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setData({ ...data, optionE: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {data.optionType === "imageanswer" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-3" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Question Image </b>{" "}
                    <span id="optional"> (optional) </span>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="questionImage"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            {data.optionType === "imageanswer" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-3" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option Image A</b>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="optionA"
                    className="form-control"
                    required="required"
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-3" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option Image B</b>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="optionB"
                    className="form-control"
                    required="required"
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-3" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option Image C</b>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="optionC"
                    className="form-control"
                    required="required"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            {data.optionType === "imageanswer" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-3" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option Image D</b>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="optionD"
                    className="form-control"
                    required="required"
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-3" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option Image E </b>{" "}
                    <span id="optional"> (optional) </span>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="optionE"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            {data.optionType === "textonly" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-5" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option A</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={data.optionA}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setData({ ...data, optionA: updatedContent });
                    }}
                  />
                </div>

                <div className="col-md-5" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option B</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={data.optionB}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setData({ ...data, optionB: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {data.optionType === "textonly" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-5" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option C</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={data.optionC}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setData({ ...data, optionC: updatedContent });
                    }}
                  />
                </div>

                <div className="col-md-5" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option D</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={data.optionD}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setData({ ...data, optionD: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {data.optionType === "textonly" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-5" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option E </b> <span id="optional">(optional) </span>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={data.optionE}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setData({ ...data, optionE: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {data.optionType === "truefalse" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-5" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Correct answer index</b>
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="form_name"
                    value={data.answer}
                    onChange={handleChange}
                    name="answer"
                  >
                    <option selected>Select correct answer</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                  </select>
                </div>
              </div>
            )}

            {data.optionType === "image" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-5" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Correct answer index</b>
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="form_name"
                    value={data.answer}
                    onChange={handleChange}
                    name="answer"
                  >
                    <option selected>Select correct answer</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </select>
                </div>
              </div>
            )}

            {data.optionType === "imageanswer" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-5" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Correct answer index</b>
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="form_name"
                    value={data.answer}
                    onChange={handleChange}
                    name="answer"
                  >
                    <option selected>Select correct answer</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </select>
                </div>
              </div>
            )}

            {data.optionType === "textonly" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-5" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Correct answer index</b>
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="form_name"
                    value={data.answer}
                    onChange={handleChange}
                    name="answer"
                  >
                    <option selected>Select correct answer</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </select>
                </div>
              </div>
            )}

            <div className="row" id="AddQuestionRow4">
              <div className="col-md-10" id="textEditor">
                <label htmlFor="form_name" id="AddQuestionHeader">
                  <b>Explanation</b> <span id="optional">(Optional)</span>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={data.explanation}
                  onChange={(event, editor) => {
                    const updatedContent = editor.getData();
                    setData({ ...data, explanation: updatedContent });
                  }}
                />
              </div>
            </div>

            <div id="AddQuestionButtonSection">
              <div className="row" id="AddQuestionRow5">
                <div className="col" id="cansel">
                  <button
                    className="btn"
                    id="AddQuestionBtnCancel"
                    onClick={() => window.history.back()}
                    type="button"
                  >
                    Cancel
                  </button>
                </div>

                <div className="col" id="create">
                  <button
                    className="btn"
                    type="submit"
                    value="submit"
                    id="AddQuestionBtnCreate"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div id="questionPart">
            <Question exam_id={data.exam_id} key={refreshQuestions}  />
          </div>
        </div>
      </div>
    </div>
  );
}
