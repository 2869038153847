import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../../config";
import Header from "../Dashboard/Header/Header";

export default function EditCreatedExam() {
  const { id } = useParams();
  const history = useHistory();
  const [exam, setExam] = useState(null);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${config.apiUrl}/v1/web/api/create-exam/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const fetchedExam = res.data.createExam;
        fetchedExam.expire_date = fetchedExam.expire_date.split("T")[0];

        const updatedExam = await getExamDetails(fetchedExam, token);
        setExam(updatedExam);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id, token]);

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setExam({
      ...exam,
      [name]: value,
    });
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${config.apiUrl}/v1/web/api/create-exam/${id}`, exam, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Updated Successfully!",
      }).then(() => {
        history.push("/add-pincode");
      });
    } catch (error) {
      console.log(error);
    }
  };

  const generatePinCode = () => {
    const pinCode = Math.floor(100000 + Math.random() * 900000).toString();
    setExam((prevExam) => ({
      ...prevExam,
      pinCode: pinCode,
    }));
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  };

  const getExamDetails = async (createExam, token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/exam/${createExam.examName}`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { examName } = res.data.createExam;
      return {
        ...createExam,
        examName: examName || "Unknown Exam Name",
      };
    } catch (error) {
      console.log(
        `Error fetching details for exam ${createExam.examName}:`,
        error
      );
      return { ...createExam, examName: "Unknown Exam Name" };
    }
  };

  if (!exam) {
    return <>Loading the data...</>;
  }

  return (
    <div>
      <Header />
      <div className="container" id="modifyPinContainer">
        <form
          className="row gy-2 gx-3 align-items-center"
          onSubmit={onSubmitForm}
        >
          <div className="col-auto">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="autoSizingInputGroup"
                name="examName"
                disabled
                value={exam.examName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="autoSizingInputGroup"
                placeholder="Pin Code"
                value={exam.pinCode}
                name="pinCode"
                readOnly
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={generatePinCode}
              >
                Generate
              </button>
            </div>
          </div>

          <div className="col-auto">
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                id="autoSizingInputGroup"
                name="expire_date"
                value={exam.expire_date}
                onChange={handleChange}
                min={getCurrentDate()}
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="autoSizingInputGroup"
                name="note"
                placeholder="Additional Note"
                value={exam.note}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-auto">
            <button type="submit" className="btn" id="submitBtn">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
