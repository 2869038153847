import React, { useState, useEffect } from "react";
import config from "../../config";
import axios from "axios";
import Header from "../Dashboard/Header/Header";
import "./history.css";

export default function History() {
  const [data, setData] = useState([]);
  const teacherId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("User not authenticated.");
        return;
      }

      try {
        const tempData = await getData(token);
        setData(tempData);
        console.log(JSON.stringify(tempData));
      } catch (error) {
        console.log("Error fetching history data:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (token) => {
    try {
      const finalURL = `${config.apiUrl}/v1/web/api/purchase/teacher/${teacherId}`;
      const res = await axios.get(finalURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const purchase = res.data?.purchase || [];

      return purchase;
    } catch (error) {
      console.log("Error fetching purchase data:", error);
      throw error;
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-LK", {
      style: "currency",
      currency: "LKR",
    }).format(amount);
  };

  return (
    <div>
      <Header />
      <div className="container" id="HistoryContainer">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">
                <i className="fas fa-list"></i>
              </th>
              <th scope="col" id="headName">
                Credit
              </th>
              <th scope="col" id="headName">
                Amount
              </th>
              <th scope="col" id="headName">
                Order ID
              </th>
              <th scope="col" id="headName">
                Date
              </th>
              <th scope="col" id="headName">
                Status
              </th>
            </tr>
          </thead>

          <tbody>
            {data.map((purchase, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td id="supplier">{purchase.noOfCredit}</td>
                <td id="supplier">{formatCurrency(purchase.amount)}</td>
                <td id="supplier">{purchase.orderId}</td>
                <td id="supplier">
                  {new Date(purchase.date).toLocaleDateString()}
                </td>
                <td>
                  <span className="purchaseStatus">{purchase.status}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
