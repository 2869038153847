import Swal from "sweetalert2";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Header from "../Dashboard/Header/Header";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import config from "../../config";

export default function EditQuestions() {
  const { id } = useParams();
  const [question, setQuestion] = useState(null);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${config.apiUrl}/v1/web/api/question/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setQuestion(res.data.question);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id, token]);

  function handleChange(evt) {
    const { name, value, files } = evt.target;

    const newValue = files ? files[0] : value;

    setQuestion({
      ...question,
      [name]: newValue,
    });
  }

  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${config.apiUrl}/v1/web/api/question/update/${id}`,
        question,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Updated Successfully!",
      }).then(() => {
        window.history.back();
        window.location.reload();
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (!question) {
    return <>Loading the data</>;
  }
  return (
    <div>
      <Header />
      <div className="container" id="AddQuestionContainer" >
      <form onSubmit={(e) => onSubmitForm(e)} style={{marginBottom:"3%"}}>
            <div className="row" id="AddQuestionRow2">
              <div className="col-md-4" id="AddQuestionCol">
                <label htmlFor="form_name" id="AddQuestionHeader">
                  <b>Question Type</b>
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  id="form_name"
                  value={question.questionType}
                  onChange={handleChange}
                  name="questionType"
                >
                  <option selected>Select question type</option>
                  <option value="Past Paper" name="Past Paper">
                    Past Paper
                  </option>
                  <option value="Model Paper" name="Model Paper">
                    Model Paper
                  </option>
                </select>
              </div>

              <div className="col-md-7" id="AddQuestionCol">
                <label htmlFor="form_name" id="AddQuestionHeader">
                  <b>Option Type</b>
                </label>
                <div className="row">
                  <div className="col-md-2">
                    <input
                      type="radio"
                      id="option1"
                      name="optionType"
                      value="truefalse"
                      defaultValue={question.optionType}
                      checked={question.optionType === "truefalse"}
                      onChange={handleChange}
                      className="custom-radio"
                    />
                    <label htmlFor="option1" className="custom-radio-label">
                      True/False
                    </label>
                  </div>

                  <div className="col-md-2">
                    <input
                      type="radio"
                      id="option2"
                      name="optionType"
                      value="image"
                      defaultValue={question.optionType}
                      checked={question.optionType === "image"}
                      onChange={handleChange}
                      className="custom-radio"
                    />
                    <label htmlFor="option2" className="custom-radio-label">
                      Images
                    </label>
                  </div>

                  <div className="col-md-3">
                    <input
                      type="radio"
                      id="option3"
                      name="optionType"
                      value="imageanswer"
                      defaultValue={question.optionType}
                      checked={question.optionType === "imageanswer"}
                      onChange={handleChange}
                      className="custom-radio"
                    />
                    <label htmlFor="option3" className="custom-radio-label">
                      Images Answer
                    </label>
                  </div>

                  <div className="col">
                    <input
                      type="radio"
                      id="option4"
                      name="optionType"
                      value="textonly"
                      defaultValue={question.optionType}
                      checked={question.optionType === "textonly"}
                      onChange={handleChange}
                      className="custom-radio"
                    />
                    <label htmlFor="option4" className="custom-radio-label">
                      Text Only
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" id="AddQuestionRow4">
              <div className="col-md-8" id="textEditor">
                <label htmlFor="form_name" id="AddQuestionHeader">
                  <b>Question title</b>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={question.questionTitle}
                  onChange={(event, editor) => {
                    const updatedContent = editor.getData();
                    setQuestion({ ...question, questionTitle: updatedContent });
                  }}
                />
              </div>
            </div>

            {question.optionType === "truefalse" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-4" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option A</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question.optionA}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setQuestion({ ...question, optionA: updatedContent });
                    }}
                  />
                </div>

                <div className="col-md-4" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option B</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question.optionB}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setQuestion({ ...question, optionB: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {question.optionType === "image" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-4" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Question Image </b>
                    <span id="optional">(optional)</span>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="questionImage"
                    className="form-control"
                    required="required"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            {question.optionType === "image" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-4" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option A</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question.optionA}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setQuestion({ ...question, optionA: updatedContent });
                    }}
                  />
                </div>

                <div className="col-md-4" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option B</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question.optionB}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setQuestion({ ...question, optionB: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {question.optionType === "image" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-4" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option C</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question.optionC}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setQuestion({ ...question, optionC: updatedContent });
                    }}
                  />
                </div>

                <div className="col-md-4" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option D</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question.optionD}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setQuestion({ ...question, optionD: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {question.optionType === "image" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-4" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option E </b> <span id="optional">(optional) </span>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question.optionE}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setQuestion({ ...question, optionE: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {question.optionType === "imageanswer" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-3" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Question Image </b>{" "}
                    <span id="optional"> (optional) </span>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="questionImage"
                    className="form-control"
                    required="required"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            {question.optionType === "imageanswer" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-3" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option Image A</b>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="optionA"
                    className="form-control"
                    required="required"
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-3" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option Image B</b>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="optionB"
                    className="form-control"
                    required="required"
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-3" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option Image C</b>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="optionC"
                    className="form-control"
                    required="required"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            {question.optionType === "imageanswer" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-3" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option Image D</b>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="optionD"
                    className="form-control"
                    required="required"
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-3" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option Image E </b>{" "}
                    <span id="optional"> (optional) </span>
                  </label>
                  <input
                    id="image"
                    type="file"
                    name="optionE"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            {question.optionType === "textonly" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-4" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option A</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question.optionA}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setQuestion({ ...question, optionA: updatedContent });
                    }}
                  />
                </div>

                <div className="col-md-4" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option B</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question.optionB}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setQuestion({ ...question, optionB: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {question.optionType === "textonly" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-4" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option C</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question.optionC}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setQuestion({ ...question, optionC: updatedContent });
                    }}
                  />
                </div>

                <div className="col-md-4" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option D</b>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question.optionD}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setQuestion({ ...question, optionD: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {question.optionType === "textonly" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-4" id="textEditor">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Option E </b> <span id="optional">(optional) </span>
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question.optionE}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      setQuestion({ ...question, optionE: updatedContent });
                    }}
                  />
                </div>
              </div>
            )}

            {question.optionType === "truefalse" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-4" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Correct answer index</b>
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="form_name"
                    value={question.answer}
                    onChange={handleChange}
                    name="answer"
                  >
                    <option selected>Select correct answer</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                  </select>
                </div>
              </div>
            )}

            {question.optionType === "image" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-4" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Correct answer index</b>
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="form_name"
                    value={question.answer}
                    onChange={handleChange}
                    name="answer"
                  >
                    <option selected>Select correct answer</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </select>
                </div>
              </div>
            )}

            {question.optionType === "imageanswer" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-4" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Correct answer index</b>
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="form_name"
                    value={question.answer}
                    onChange={handleChange}
                    name="answer"
                  >
                    <option selected>Select correct answer</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </select>
                </div>
              </div>
            )}

            {question.optionType === "textonly" && (
              <div className="row" id="AddQuestionRow3">
                <div className="col-md-4" id="AddQuestionCol">
                  <label htmlFor="form_name" id="AddQuestionHeader">
                    <b>Correct answer index</b>
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="form_name"
                    value={question.answer}
                    onChange={handleChange}
                    name="answer"
                  >
                    <option selected>Select correct answer</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </select>
                </div>
              </div>
            )}

            <div className="row" id="AddQuestionRow4">
              <div className="col-md-8" id="textEditor">
                <label htmlFor="form_name" id="AddQuestionHeader">
                  <b>Explanation</b> <span id="optional">(Optional)</span>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={question.explanation}
                  onChange={(event, editor) => {
                    const updatedContent = editor.getData();
                    setQuestion({ ...question, explanation: updatedContent });
                  }}
                />
              </div>
            </div>

            <div id="AddQuestionButtonSection">
              <div className="row" id="AddQuestionRow5">
                <div className="col" id="cansel">
                  <button
                    className="btn"
                    id="AddQuestionBtnCancel"
                    onClick={() => window.history.back()}
                    type="button"
                  >
                    Cancel
                  </button>
                </div>

                <div className="col" id="create">
                  <a href="/Add Questions">
                    <button
                      className="btn"
                      type="submit"
                      value="submit"
                      id="AddQuestionBtnCreate"
                    >
                      Create
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
    </div>
  );
}
