import React, { useState } from "react";
import config from "../../config";
import Swal from "sweetalert2";
import axios from "axios";
import Header from "../Admin Dashboard/Header/Header";
import "./banner.css";
import BannerList from "./BannerList";

export default function AddBanner() {
  const [data, setData] = useState({
    image: null,
    expire_date: "",
    visibility: "",
  });
  const [refreshQuestions, setRefreshQuestions] = useState(false);

  function handleChange(evt) {
    const name = evt.target.name;
    let value = evt.target.value;

    if (name === "image") {
      value = evt.target.files[0];
    }

    setData({
      ...data,
      [name]: value,
    });
  }

  const onSubmitForm = async (e) => {
    try {
      e.preventDefault();

      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("image", data.image);
      formData.append("expire_date", data.expire_date);
      formData.append("visibility", data.visibility);

      const res = await axios({
        method: "post",
        baseURL: config.apiUrl,
        url: "/v1/web/api/banner",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(res.data);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data Saved Successfully!",
      }).then(() => {
        setRefreshQuestions((prev) => !prev);
        setData({
          image: "",
          expire_date: "",
          visibility: "",
        });
      });
    } catch (error) {
      console.log(error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while saving the data. Please try again.",
      });
    }
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }

  return (
    <div>
      <Header />
      <div className="container" id="bannerContainer">
        <form onSubmit={(e) => onSubmitForm(e)} id="bannerForm">
          <div class="mb-3">
            <label for="exampleInputEmail1" className="form-label">
              Banner Image
            </label>
            <input
              type="file"
              className="form-control"
              name="image"
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label for="exampleInputPassword1" className="form-label">
              Expire Date
            </label>
            <input
              type="date"
              className="form-control"
              id="exampleInputPassword1"
              placeholder="enter expire date"
              name="expire_date"
              value={data.expire_date}
              onChange={handleChange}
              min={getCurrentDate()}
            />
          </div>
          <div className="mb-3">
            <label for="exampleInputPassword1" className="form-label">
              Visibility
            </label>
            <select
              class="form-select"
              aria-label="Default select example"
              name="visibility"
              value={data.visibility}
              onChange={handleChange}
            >
              <option selected>Open this select menu</option>
              <option value="true">True</option>
              <option value="false">Flase</option>
            </select>
          </div>

          <button type="submit" value="submit" className="btn" id="bannerBtn">
            Add Banner
          </button>
        </form>

        <BannerList key={refreshQuestions} />
      </div>
    </div>
  );
}
