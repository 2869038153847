
const config = {
  apiUrl: "https://devapi.fedesai.com",
  login: "/",
  register: "/teacher/register",
  merchant_id: "1226655",
  merchant_secret: "MjQyODkyMjI3NDIxOTMxNDg4MzI5MTc2MTE2NTcxMjg5Mjg4Ng==",
};

export default config;
