import React from "react";
import "./SideBar.css";
import navList from "../../Admin Dashboard/AdminData";
import NavItem from "./NavItem";
import Swal from "sweetalert2";

function SideBar() {
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log out!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        window.location.href = "/";
      }
    });
  };

  return (
    <aside id="sidebar" className="sidebar">
      <div id="sideBarDiv">
        <img id="sidebarLogo" src="/images/face-detection.png" alt="fedes" />
      </div>
      <br />
      <ul className="sidebar-nav" id="sidebar-nav">
        {navList.map((nav) => (
          <NavItem key={nav._id} nav={nav} />
        ))}
      </ul>
      <button id="logoutBtn" onClick={handleLogout}>
        <i className="bi bi-box-arrow-right"></i>&nbsp;Log out
      </button>
    </aside>
  );
}

export default SideBar;
